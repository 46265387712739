/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */
import { Stripe, loadStripe } from '@stripe/stripe-js'
import {NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY} from "../../config/CONSTANTS";


let stripePromise;
const getStripe = () => {
    if (!stripePromise) {
        stripePromise = loadStripe(NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY)
    }
    return stripePromise
}

export default getStripe