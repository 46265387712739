import React from "react";
import {Dialog, DialogContent, Box, withStyles, Typography, IconButton, DialogTitle} from "@material-ui/core";
import FormDialogTitle from "./FormDialogTitle";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: theme.spacing(3),
    maxWidth: 810,
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
    width: "100vw",

  },
  actions: {
    marginTop: theme.spacing(2)
  },
  dialogPaperScrollPaper: {
    maxHeight: "none",
    height:'100%',
    maxWidth: 810,
    width: "100vw",


  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: 0,

  }
}));


/**
 * A Wrapper around the Dialog component to create centered
 * Login, Register or other Dialogs.
 */
function FormDialog(props) {
  const classes = useStyles()

  const {
    open,
    onClose,
    loading,
    headline,
    onFormSubmit,
    content,
    actions,
    hideBackdrop,
    disclaimer,
    displayBack,
    clickBack
  } = props;
  return (
    <Dialog
        // maxWidth={'lg'}
        // fullWidth={'lg'}
        disableBackdropClick={props.disableBackdropClick}
      open={open}
      onClose={onClose}
      // disableBackdropClick={loading}
      disableEscapeKeyDown={loading}
      classes={{
        paper: classes.dialogPaper,
        paperScrollPaper: classes.dialogPaperScrollPaper
      }}
      hideBackdrop={hideBackdrop ? hideBackdrop : false}
    >
      <DialogTitle
          style={{
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop:0,
            width: "100%",
          }}
          disableTypography
      >
        <Box display="flex" justifyContent={"flex-end"} p={1}>
          {displayBack &&
          <Box p={1} flexGrow={1} >
            <IconButton
                onClick={clickBack}
                style={{ marginLeft: 24, marginTop: 12 }}
                // disabled={disabled}
                aria-label="Back"
            >
              <ArrowBackIcon />

            </IconButton>
          </Box>

          }
          <Box p={1} >
            <IconButton
                onClick={onClose}
                style={{ marginRight: 24, marginTop: 12 }}
                // disabled={disabled}
                aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

      </DialogTitle>

      <Container  style={{height:'100vh', marginTop:'5vh'}} maxWidth={'sm'}>

      <FormDialogTitle
        title={headline}
        onClose={onClose}
        disabled={loading}
      />
      <DialogContent className={classes.dialogContent}>
        <form onSubmit={onFormSubmit}>
          <div>{content}</div>
          <Box width="100%" className={classes.actions}>
            {actions}
          </Box>
        </form>
      </DialogContent>
      </Container>

    </Dialog>
  );
}


export default FormDialog;
