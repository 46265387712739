import React, {Fragment, useEffect, useRef, PureComponent, useState} from 'react';
import clsx from 'clsx';
import { makeStyles,alpha } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {mainListItems, mainListItemsWebsite, SecondaryListItems} from '../components/listItems';
import * as CONSTANTS from "../config/CONSTANTS";
import Copyright from "../components/Copyright";
import HoverPlusButton from "../components/HoverPlusButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Button, CircularProgress} from "@material-ui/core";
import {adManager, hostManager, rootManager, userManager} from "../util/apiRequest";
import {MIN_ACCOUNT_PAYOUT} from "../config/CONSTANTS";
import {useNavigate} from "react-router-dom";
import {  LineChart, Line, BarChart, Bar, XAxis, YAxis, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import DeviceList from "../components/DeviceList";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Title from "../components/Title";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import {Tooltip as MaterialTooltip} from "@material-ui/core";


function ChartToggle(props) {
    const [alignment, setAlignment] = React.useState("Clicks & Impressions");

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
        (props.notifyParent && props.notifyParent(newAlignment))
    };

    return (
        <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange}
        >
            <ToggleButton value="Clicks & Impressions">Clicks & Impressions</ToggleButton>
            <ToggleButton value="Click through rate">Click through rate</ToggleButton>
        </ToggleButtonGroup>
    );
}

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
    "July", "Aug", "Sept", "Oct", "Nov", "Dec"
];
const data = [
    {
        name: `${monthNames[new Date().getMonth()]}`,
        clicks: 0,
        impressions: 0,
    }
];

function cleanDivide(num,den){
    if(den === 0){return  0}
    return num / den;
}

const Chart = (props) =>{
    const [display,setDisplay] = useState(props.display);
    useEffect(()=>{setDisplay(props.display)},[props.display])
        return (
                <ResponsiveContainer width={"98%"} aspect={2}>
                    {(display === 'Click through rate')?
                        <LineChart
                            width={500}
                            height={300}
                            data={props.data || data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="click through rate" stroke="#00a81f" />
                        </LineChart>
                        :
                        <BarChart
                            width={500}
                            height={300}
                            data={props.data || data}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="impressions" stackId="a" fill="#8884d8" />
                            <Bar dataKey="clicks" stackId="a" fill="#82ca9d" />
                        </BarChart>
                    }
            </ResponsiveContainer>
        );
}


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor:theme.palette.background.appGray
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '20ch',
            '&:focus': {
                width: '22ch',
            },
        },
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        // marginRight: 36,
        marginRight: "2%",

    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        visibility:"visible",

    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
        [theme.breakpoints.down('sm')]: {
            width: 0,
            // visibility:"hidden",
            display:'none',
        },
        [theme.breakpoints.up('md')]: {
            width: theme.spacing(9),
            visibility:"visible",
        },
        [theme.breakpoints.up('lg')]: {
            width: theme.spacing(9),
            visibility:"visible",
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 350,
    },
    fixedHeightMax:{
        height: 480,
    },
    imgIcon: {
        marginTop:0,
        marginBottom:0,
        paddingTop:0,
        paddingBottom:0
    },
}));

const UserType = {Account:"Account", Customer:"Customer"}


function daysInMonth (page=0) {
    let d = new Date();
    d.setMonth(d.getMonth() - page)
    return new Date(d.getFullYear(), d.getMonth()+1, 0).getDate();
}

export default function Dashboard(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [displayAppLogo, setDisplayAppLogo] = React.useState(true);

    const [userType, setUserType] = React.useState();
    const [myAds, setMyAds] = React.useState();
    const [myDomains, setMyDomains] = React.useState();
    const [myWebsites, setMyWebsites] = React.useState();
    const [myVerifyStates, setMyVerifyStates] = React.useState();
    const [displayUpdateAccountLink,setDisplayUpdateAccountLink] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [elementIdx, setElementIdx] = React.useState(0);

    const [balance, setBalance] = React.useState();
    const [balStr, setBalStr] = React.useState();
    const [pendingBalance, setPendingBalance] = React.useState();
    const [pendingBalStr, setPendingBalStr] = React.useState();

    const [activeChartIdx, setActiveChartIdx] = React.useState();
    const [activeData, setActiveData] = React.useState();
    const [activeStats, setActiveStats] = React.useState({});

    const [displayStat, setDisplayStat] = React.useState('Clicks & Impressions');

    const [payoutSucceeded, setPayoutSucceeded] = React.useState();
    const [usage, setUsage] = React.useState();
    const [token, setToken] = React.useState(window.localStorage.getItem("token"));

    let navigate = useNavigate();

    useEffect(()=> {
        const token = window.localStorage.getItem("token");
        let params = (new URL(window.location.href)).searchParams;
        let code = params.get('code');
        let state = params.get('state');

        if(code && code.length > 0 && state && state.length > 0){
            userManager.get(token,undefined,`/token?code=${code}&state=${state}`).then((r)=>{
                if(r.status_code === 200){
                    navigate('/website/create')
                }
            }).catch(e=>console.error(e))
        } else {
            rootManager.get(token,undefined,'state').then(r=> {
            })//@TODO ?
        }
    },[]);


    const fetchUsage = () =>{
        userManager.get(token,undefined,'/usage').then(r=> {
            if(r.status_code === 200){
                setUsage(r.approxAmount)
            }
        }).catch(e=>{console.error(e)})
    }

    const coalesceData = (data)=>{
        let sumClick = 0;
        let sumImpression = 0;
        let n = 0;
        let minClick = data.length > 0? Number.MAX_VALUE: 0;
        let maxClick = 0;
        let minImpression = data.length > 0? Number.MAX_VALUE: 0;
        let maxImpression = 0;

        let d = new Date();
        d.setMonth(d.getMonth() - page);
        const currMo = monthNames[d.getMonth()];
        const maxDays = daysInMonth(page);
        let startDay = 0;
        let endDay = 0;
        let filled = [];

        let m = {}
        data.forEach((v)=>{
            m[new Date(v.day).getDate()] = v;
        })
        for (let i = 0; i <= maxDays; i++) {
            let found = m[i];
            if(found){
                found.clicks = found.clicks || 0;
                found.impressions = found.impressions || 0;
                filled.push({
                    name: `${currMo} ${i}`,
                    clicks: found.clicks,
                    impressions: found.impressions,
                    'click through rate':+(cleanDivide(found.clicks,found.impressions)*100).toFixed(2)
                });
                n++;
                sumClick+=found.clicks;
                sumImpression+=found.impressions;
                minClick = found.clicks<minClick? found.clicks:minClick;
                minImpression = found.impressions<minImpression? found.impressions:minImpression;
                maxClick = found.clicks>maxClick? found.clicks:maxClick;
                maxImpression = found.impressions>maxImpression? found.impressions:maxImpression;
            }else{
                filled.push({
                    name:`${currMo} ${i}`,
                    clicks:0,
                    impressions:0,
                    'click through rate':0
                })
            }
        }
        return {data:filled, minClick, minImpression, maxClick, maxImpression,
            monthly_ctr:+(cleanDivide(sumClick,sumImpression)*100).toFixed(2),
            avgClick:+(cleanDivide(sumClick,n)).toFixed(2),
            avgImpression:+(cleanDivide(sumImpression,n)).toFixed(2),
        };
    }

    useEffect(()=>{
        userManager.get(token,undefined,'/type').then(r=>{
            if(r.status_code === 402){
                window.location.href='/card/?update=true';
            }
            if(r.status_code === 200){
                setUserType(r.type);
                if(r.type === UserType.Account){
                    if(r.my_websites){
                        setMyWebsites(r.my_websites);
                        if(r.my_websites.length > 0){
                            if(r.my_verify_states[elementIdx] !== false){
                                hostManager.get(token,undefined,`?page=${page}&website_id=${r.my_websites[elementIdx]}`).then(d=>{
                                    setActiveChartIdx(0);
                                    let {data, ...dataParsed} = coalesceData(d.data);
                                    setActiveStats(dataParsed);
                                    setActiveData(data);
                                })
                            }
                        }
                    }
                    if(r.my_domains && r.my_domains.length > 0){
                        setMyDomains(r.my_domains);
                        setMyVerifyStates(r.my_verify_states)
                        userManager.get(token,undefined,'/balance').then(r=> {
                            if(r.status_code === 200){
                                setBalance(r.balance);
                                setBalStr(r.balStr);
                                setPendingBalance(r.pendingBalanceValue)
                                setPendingBalStr(r.pendingBalStr);
                            }
                        }).catch(e=>{console.error(e)})
                        setDisplayUpdateAccountLink('')
                    } else if(r.requirements_due) {
                        setDisplayUpdateAccountLink(r.updateLink)
                    } else {
                        navigate('/website/create')
                    }


                } else if(r.type === UserType.Customer){
                    if(r.my_ads){
                        setMyAds(r.my_ads)
                        if(r.my_ads.length > 0){
                            adManager.get(token,undefined,`?page=${page}&ad_id=${r.my_ads[elementIdx]}`).then(d=>{
                                setActiveChartIdx(0);
                                let {data, ...dataParsed} =  coalesceData(d.data);
                                setActiveStats(dataParsed);
                                setActiveData(data);
                            })
                            fetchUsage()
                        } else {
                            navigate('/create')
                        }
                    }
                }
            }
        }).catch(e=>{
            console.error("type error",e);
            if(e && e.response && (typeof e.response.status !== 'undefined')){
                if(e.response.status === 401){
                    navigate('/?signUp=true')
                } else if(e.response.status === 402){
                    navigate('/card?update=true')
                }
            }
        })
    },[page, elementIdx])


    const showAppName = useMediaQuery('(min-width:360px)');


    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const payout = (e)=>{
        if(balance && (balance.amount >= MIN_ACCOUNT_PAYOUT)){
            userManager.get(token,undefined,'/payout').then(r=> {
                if(r.status_code === 200){
                    setPayoutSucceeded(true)
                } else if (r.status_code === 401 && r.redirect_uri){
                    navigate(r.redirect_uri)
                }
            }).catch(e=>{console.error(e)})
        }
    }
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    {displayAppLogo &&
                    <React.Fragment>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            href='/'
                            className={classes.imgIcon}
                        >
                            <img className={classes.imgIcon} src="/favicon.svg" width={40} height={40}
                                 alt="Notifycam logo"/>
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            {showAppName &&
                            <a href='/' style={{textDecoration: 'none', color: 'inherit'}}>
                                {CONSTANTS.APP_NAME}
                            </a>
                            }
                        </Typography>
                    </React.Fragment>
                    }
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}>
                <div className={classes.toolbarIcon}>
                    <Typography>
                        Welcome back!
                    </Typography>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>{userType === UserType.Customer? mainListItems: mainListItemsWebsite}</List>
                <Divider />
                <List><SecondaryListItems/></List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={1} direction="column" style={{padding:5}}>
                        {(!displayUpdateAccountLink && (userType === UserType.Account)) &&
                            <Fragment>
                                <Grid item>
                                    {payoutSucceeded?
                                        "Payout succeeded!"
                                        :
                                        !( balance && (balance.amount >= MIN_ACCOUNT_PAYOUT))?
                                            <MaterialTooltip title={<p style={{fontSize:'1.5em'}}>{`Minimum balance to payout is $${MIN_ACCOUNT_PAYOUT/100}`}</p>}>
                                              <span>
                                            <Button style={{textTransform:'none',cursor:'pointer'}} disabled={true} onClick={payout}>Available payout: {(typeof balStr !== "undefined") && balStr}</Button>
                                              </span>
                                            </MaterialTooltip>
                                            :
                                            <Button style={{textTransform:'none',cursor:'pointer'}} onClick={payout}>Available payout: {(typeof balStr !== "undefined") && balStr}</Button>
                                    }

                                    {/*//@TODO balance and balStr are confused and shared between customer and account but not set consistently*/}
                                </Grid>

                                    {( pendingBalance && (pendingBalance.amount >= 0)) &&
                                    <Grid item>
                                            <Typography>Available within 7 days: {(typeof pendingBalStr !== "undefined") && pendingBalStr}</Typography>
                                    </Grid>
                                    }

                            </Fragment>
                        }


                        {(displayUpdateAccountLink && userType === UserType.Account) &&
                            <Button variant="outlined" style={{color:'red'}} onClick={()=>{window.open(displayUpdateAccountLink, '_blank').focus();}}>Error: please click to update your account information</Button>
                        }
                        {/*{userType === UserType.Customer &&*/}
                        {/*    <Grid item xs={12} md={4} lg={3}>*/}
                        {/*            <Button style={{textTransform:'none', fontSize:15, cursor:'default'}} >Monthly budget usage (all ads): {balance}</Button>*/}
                        {/*    </Grid>*/}
                        {/*}*/}
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <IconButton style={{fontSize:'1.15em'}} disabled={(!(myAds && myAds.length > 0) && !(myWebsites && myWebsites.length > 0))} onClick={()=>{setPage(page+1)}}>
                                    <ChevronLeftIcon />
                                    Previous month
                                </IconButton>
                                <IconButton style={{fontSize:'1.15em'}} disabled={page===0} onClick={()=>{setPage(page > 0? page-1: page)}}>
                                    Next month
                                    <ChevronRightIcon />
                                </IconButton>
                            </Grid>
                        <Grid  container
                               direction="row"
                               justifyContent="flex-start"
                               alignItems="flex-start">

                        <Chart display={displayStat} data={activeData}/>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <ChartToggle notifyParent={(upstate)=>{setDisplayStat(upstate)}} />

                        </Grid>

                        {typeof userType === 'undefined' &&
                        <Grid item xs={12} md={4} lg={3}>
                            <Typography>Loading data...</Typography>
                            <CircularProgress size={64} />
                        </Grid>
                        }
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                        <Grid item xs={12} md={6} lg={4}>
                            <Paper className={fixedHeightPaper}>
                                <DeviceList token={token} onOptionClick={(e)=>{setElementIdx(e)}} type={userType} website_ids={myWebsites} websites={myDomains} verifyStates={myVerifyStates} ads={myAds} noDevFound={!(myAds && myAds.length > 0)} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Paper className={fixedHeightPaper}>
                                <Title>
                                    {userType === UserType.Customer? "Campaign performance":"Performance"}
                                </Title>
                                {activeStats && (typeof activeStats.monthly_ctr !== 'undefined') &&
                                    <Fragment>
                                        <div>
                                            This ad's monthly click through rate is <b>{activeStats.monthly_ctr}%</b>
                                        </div>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell align="right">min</TableCell>
                                                        <TableCell align="right">average</TableCell>
                                                        <TableCell align="right">max</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow
                                                        key={'Clicks'}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            Clicks
                                                        </TableCell>
                                                        <TableCell align="right">{activeStats.minClick}</TableCell>
                                                        <TableCell align="right">{activeStats.avgClick}</TableCell>
                                                        <TableCell align="right">{activeStats.maxClick}</TableCell>
                                                    </TableRow>
                                                    <TableRow
                                                        key={'Impressions'}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            Impressions
                                                        </TableCell>
                                                        <TableCell align="right">{activeStats.minImpression}</TableCell>
                                                        <TableCell align="right">{activeStats.avgImpression}</TableCell>
                                                        <TableCell align="right">{activeStats.maxImpression}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Fragment>

                                }

                            </Paper>
                        </Grid>
                        </Grid>
                    </Grid>
                    <HoverPlusButton userType={userType}/>
                    <Box pt={4}>
                        <Copyright />
                    </Box>
                </Container>
            </main>
        </div>
    );
}


