import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import {Fragment} from "react";

function valueLabelFormat(value) {
    return `${value} $/Month`;
}

function calculateValue(value) {
    return value;
}
export default function LinearSlider(props) {
    const [value, setValue] = React.useState(props.defaultValue);

    const handleChange = (event, newValue) => {
        if (typeof newValue === 'number') {
            setValue(newValue);
            props.onChange(newValue)
        }
    };

    return (
        <Fragment>
            <Typography id="non-linear-slider" gutterBottom>
                <b>{value}</b>{` $USD/Month`}
            </Typography>
            <Slider
                value={value}
                min={props.minBudget}
                step={1}
                max={500}
                scale={calculateValue}
                getAriaValueText={valueLabelFormat}
                valueLabelFormat={valueLabelFormat}
                onChange={handleChange}
                valueLabelDisplay="auto"
                aria-labelledby="non-linear-slider"
                aria-label="Monthly budget"
            />
        </Fragment>
    );
}