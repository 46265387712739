import React, {Fragment, useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Title from "../Title";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import * as CONSTANTS from "../../config/CONSTANTS";
import {Hidden} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    buttons: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    centerVertically:{
        minHeight:'50%'
    }
}));

async function dataUrlToFile(dataUrl, imtype){
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], "placeholder", { type: imtype });
}

export default function UploadAd(props) {
    const classes = useStyles();
    const [attachedImage, setAttachedImage] = React.useState();
    const [imageSrc, setImageSrc] = React.useState();
    const [imageWidth, setImageWidth] = React.useState();
    const [imageHeight, setImageHeight] = React.useState();

    const [badImage, setBadImage] = React.useState(false);

    useEffect(()=>{
        try{
            if(props.editimg){
                loadImage(props.editimg)
            } else if(props.img64){
                let useURL;
                let imtype;
                if(props.img64.startsWith('/9j/')){
                    useURL = `data:image/jpeg;base64,${props.img64}`;
                    imtype='image/jpeg'
                } else {
                    useURL = `data:image/png;base64,${props.img64}`;
                    imtype='image/png'
                }
                dataUrlToFile(useURL, imtype).then(b=>{
                    loadImage(b)
                })
            }
        } catch (e) {
            console.error(e);
        }
    },[props.img64])

    const loadImage = (file) =>{
        setAttachedImage(file);
        let reader  = new FileReader();
        reader.onload = function(e)  {
            setImageSrc(e.target.result);
        }
        reader.readAsDataURL(file);
    }


    const handleVideoUpload = (event) => {
        try{
            if(event && event.target && event.target.files && event.target.files.length){
                if(event.target.files[0].size > 1.0485*(10**6)){
                    setBadImage(true)
                } else{
                    setBadImage(false)
                    loadImage(event.target.files[0])
                }
            }
        }
        catch (e) {
            console.error("Error selecting file",e)
        }
    };


    return (
        <React.Fragment>
            <Title>
                Upload image
            </Title>
            {(!imageSrc) &&
                <Hidden mdDown>
                    <Typography variant={'h12'} >
                        Your advertisement must be content-safe and adhere to the <a href={'https://notifycam.com/terms#user-provided-content'} target="_blank" rel="noopener noreferrer">Terms of Use</a>.
                    </Typography>
                </Hidden>
            }
            <Typography variant={'h12'} >
                Do not include a QR code in your image as it will be generated for you.
            </Typography>
            <Grid   container
                    className={classes.centerVertically}
                    direction="column"
                    alignItems="center"
                    justifyContent="center">

                <Grid style={{display:'flex',marginLeft:'auto'}} item>
                    <Button
                        disabled={typeof attachedImage === 'undefined'}
                        variant="contained"
                        color="primary"
                        onClick={()=>{props.onChange(attachedImage, imageWidth, imageHeight);props.handleNext();}}
                    >
                        {"Next"}
                    </Button>
                </Grid>
                <Grid item>
                    <Fragment>
                        <input onChange={handleVideoUpload}  style={{display:'none'}} id="icon-button-file" type="file" accept="image/png, image/jpeg"/>
                        <label htmlFor="icon-button-file">
                            <IconButton component="span">
                                <Tooltip title="Ad image" aria-label="Ad image">
                                    <PhotoCamera/>
                                </Tooltip>
                                Upload image

                            </IconButton>

                        </label>
                    </Fragment>

                    {badImage &&
                    <Typography style={{color:'red'}} >
                        Please ensure your image is less than 1mb and its width is greater than its height.
                    </Typography>
                    }

                    {imageSrc &&
                    <img onLoad={(e)=>{
                        setImageWidth(e.target.naturalWidth);
                        setImageHeight(e.target.naturalHeight);
                        if(e.target.naturalWidth < e.target.naturalHeight){
                            setBadImage(true);
                            setImageSrc(undefined);
                            setAttachedImage(undefined)
                        }
                    }} style={{width:"100%"}} src={imageSrc}/>
                    }


                </Grid>
            </Grid>

        </React.Fragment>
    );
}