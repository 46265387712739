import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor:'none',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),

    },
}));

export default function RemoveDeviceModal(props) {
    const classes = useStyles();
    const [open,setOpen]=useState(props.open);
    useEffect(()=>{
        setOpen(props.open)
    },[props.open])
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={(e)=>{props.handleClose(e);
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 100,
                }}
            >
                <Fade in={open} >
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">
                            {props.type==='Customer'?'Are you sure you want to remove this Ad?':'Are you sure you want to remove this website?' }
                        </h2>
                        {/*<p id="transition-modal-description">You can <a href={'/user'}>upgrade your plan</a> instead if you wish to have more devices*/}
                        {/*</p>*/}
                        <Grid container>
                            <Grid item>
                                <Button  type={'secondary'} onClick={()=>{props.performAction();}}>
                                    {props.type==='Customer'?'Remove Ad':'Remove website' }
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button color={'primary'} variant={'outlined'} onClick={(e)=> {
                                    props.handleClose(e)
                                }}>Cancel</Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}