import React, {Fragment} from "react";

export const DOCUMENT_TAB_TITLE="Notifycam";
export const WEBAPP_URL="https://app.notifycam.com/";
export const LANDING_PAGE_URL="https://notifycam.com/";
export const AUTH_URL="https://auth.notifycam.com/";

export const APP_NAME="Notifycam";

export const INDEX_SUBTITLE=<Fragment>Merge ads with your content</Fragment>; //
const DEV_MODE = false;//@TODO
export const API_URI=DEV_MODE?"http://localhost:8080":"https://api.notifycam.com";
export const EDGE_API_URI=DEV_MODE?"http://localhost:8281":"https://img.notifycam.com";
export const AUTH_API_URI=DEV_MODE?"http://localhost:8080/leave":'https://auth.notifycam.com'
export const AUTH_API_URI_LOGOUT=DEV_MODE?"http://localhost:8080/leave":'https://auth.notifycam.com/leave'

export const MIN_ACCOUNT_PAYOUT=1000;//In cents
export const PLAY_APP_STORE_URL=""
export const TWITTER_URL="https://twitter.com/notifycam"

export const EMAIL="info@notifycam.com";
export const MAIL_TO="mailto:info@notifycam.com"
export const LINKEDIN_SHARE = "https://www.linkedin.com/sharing/share-offsite/?url="
export const FACEBOOK_APP_ID="";
export const CONFIRMATION_CODE_SIZE=6;
export const DEFAULT_FPS = 4;
export const NOTIFICATIONS_PER_PAGE = 10;
export const MAX_EMAIL_SMS_PERIOD = 10; //minutes
export const NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY="pk_test_51JtlD6Dg47i4yz5eUIsZRzI2Aodw7zii5yu3Wd0honoiN0hgMbXyzZvaTf4ELzQ8zOqgH38fIUEMm4rMrA5TyHdA00w7mAIXNa";
export const ID_SIZE = 12;

