import React, {Fragment, useEffect, useRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
// import {logDeletion, postDeleteUser, postForgotPassword} from "../../../../lib/clientUtil/apiManager";
import {CONFIRMATION_CODE_SIZE, MAIL_TO} from "../../config/CONSTANTS";
import {TextField} from "@material-ui/core";
import VisibilityPasswordTextField from "../RegisterLogin/VisibilityPasswordTextField";
// import {attachCaptchaToken} from "../../../../lib/recaptcha";
import ButtonCircularProgress from "../RegisterLogin/ButtonCircularProgress";
import {userManager} from "../../util/apiRequest";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor:'red',
        borderWidth:'2px',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),

    },
}));

export default function DeleteModal(props) {
    const classes = useStyles();
    const [understand, setUnderstand] = useState(props.failedDeletion);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteDisabled, setDeleteDisabled] = useState(true);
    const registerPassword = useRef();
    const [message, setMessage] = useState("");
    const [target, setTarget] = useState("");

    const clearPassword= ()=>{
        if(registerPassword && registerPassword.current && registerPassword.current.value){
            registerPassword.current.value='';
            setIsPasswordVisible(false);
        }
    }



    const submitDeleteUser = ()=>{
        setIsLoading(true);

        let userInfo = {
            password:registerPassword.current.value,
        };

        userManager.post(window.localStorage.getItem('token'),userInfo,'/delete').then((r)=>{
            setIsLoading(false);
            if(r){
                if(r.status_code === 200){
                    window.localStorage.clear()
                    window.location.href = '/?signUp=true'
                }
                else{
                    setMessage(r.message)
                    setTarget(r.target);
                    clearPassword()
                }
            }
        }).catch(e=>{
            setIsLoading(false);
            setMessage(e.response.data.message)
            setTarget(e.response.data.target);
            clearPassword()
        })
    }

    const captureEmptyInput=(e)=>{
        let password=registerPassword.current.value.length;
        setDeleteDisabled(!(password));
        if(message && message.length){setMessage('');setTarget('')}
    }

    return (
        <div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props.open}
                onClose={()=>{clearPassword();props.handleClose();}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 100,
                }}
            >
                <Fade in={props.open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">
                            Delete account
                        </h2>
                        <p id="transition-modal-description">
                            Are you sure you want to delete your account?
                        </p>
                        {!understand?
                        <p>If you have unanswered questions, please <a href={MAIL_TO}>contact us</a>
                            {" "}before deleting your account. Also, checkout all our <a href={'/getting-started'}>tutorials</a></p>
                            :
                            <p>
                                Enter your credentials to confirm it's you
                            </p>
                        }
                        <Grid container>
                            {understand?
                                <Fragment>
                                    <form method="POST" onSubmit={(e)=>{e.preventDefault();submitDeleteUser();}}>
                                        <input type={'hidden'} name={'_csrf'} value={props.csrfToken} />
                                        <Grid item>
                                                <VisibilityPasswordTextField
                                                    onChange={captureEmptyInput}
                                                    style={{marginTop: 5, marginBottom: 0}}
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    error={target === "password" && message && message.length}
                                                    label={"Password"}
                                                    inputRef={registerPassword}
                                                    autoComplete="off"
                                                    helperText={(() => {
                                                        if (target === "password" && message && message.length) {
                                                            return message;
                                                        }
                                                        return null;
                                                    })()}
                                                    FormHelperTextProps={{error: true}}
                                                    isVisible={isPasswordVisible}
                                                    onVisibilityChange={setIsPasswordVisible}
                                                />
                                            {isLoading && <ButtonCircularProgress />}
                                        </Grid>
                                        {props.failedDeletion &&
                                        <p style={{color:'red'}}>
                                            Something went wrong. Please check your credentials or
                                            {" "}<a href={MAIL_TO}>contact support</a>
                                        </p>
                                        }

                                    <Grid style={{marginTop:10}} item>
                                        <Button disabled={deleteDisabled} variant={'outlined'} style={{color:"red", marginRight:20}}  type={'submit'} >Delete account</Button>

                                        <Button color={'primary'} variant={'outlined'} onClick={()=> {
                                            clearPassword();
                                            props.handleClose()
                                        }}>Cancel</Button>
                                    </Grid>
                                    </form>

                                </Fragment>
                                :
                                <Fragment>
                                    <Grid item>
                                        <Button variant={'outlined'} style={{color:"red", marginRight:10}}  type={'submit'} onClick={()=>{setUnderstand(true)}}>I understand I may not be able to recover my account</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button color={'primary'} variant={'outlined'} onClick={()=> {
                                            clearPassword();
                                            props.handleClose()
                                        }}>Cancel</Button>
                                    </Grid>
                                </Fragment>
                            }
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}