import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";

import InputAdornment from "@material-ui/core/InputAdornment";
import DevicesIcon from '@material-ui/icons/Devices';
import LoadingButton from "../LoadingButton";

const useStyles = makeStyles((theme) => ({
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    centerVertically:{
        minHeight:'40%'
    }
}));
function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export default function NameDevice(props) {
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [val, setVal] =   React.useState("");
    const [hasWritten, setHasWritten] =   React.useState(false);
    const [nextDisabled, setNextDisabled] =   React.useState(true);

    useEffect(()=>{
        const n = props.getDeviceName();
        setVal(n)
        setNextDisabled(!(n && n.length))
    },[])

    const onChange = (n) => {
        const [worked, msg] =  props.setName(n.target.value);
        setHasWritten(true)
        if(!worked){
            setErrorMessage(msg);
            setVal(n.target.value)
        }
        else{
            setErrorMessage("");
            setVal(n.target.value)
        }
        setNextDisabled(!worked)
    };



    return (
        <React.Fragment>
            <Grid   container
                    className={classes.centerVertically}
                    // spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center">
                <Grid item>
                    <TextField
                            InputProps={{
                                endAdornment:(
                                <InputAdornment position="end">
                                    <LoadingButton
                                        style={{marginLeft:'auto',justifyContent:'flex-end',flex:1,padding:'6px 16px 6px 16px', height:36}}
                                        disabled={nextDisabled}
                                        onClick={()=>{
                                            return props.onFinish();
                                        }}
                                        onComplete={async (r)=>{window.location.href='/'}} onError={(e)=>{console.error('error',e)}}>
                                        Finish
                                    </LoadingButton>
                                </InputAdornment>
                                ),
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <DevicesIcon />
                                    </InputAdornment>
                                ),
                            }}
                            error={errorMessage.length!==0} helperText={errorMessage} onChange={onChange} value={val}
                                   id="outlined-basic" label="Enter a device name" placeholder={props.first_name? props.first_name+"'s laptop":"My laptop"}
                                   variant="outlined" />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}