import React, {Fragment, useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Title from "../Title";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    buttons: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    centerVertically:{
        margin:'auto'
    }
}));

export default function WebsiteLocation(props) {
    const classes = useStyles();
    const [hostUri, setHostUri] = React.useState();
    const [loading, setLoading] = React.useState(false);

    const badHostUri = (h) =>{
        if (typeof h !== 'string'){
            return true
        }
        h = h.toLowerCase();
        if(h.includes('https://') || h.includes('http://') || h.includes('/')|| h.includes('@') || h.includes(' ') || h.includes('\\')|| h.includes('\n') || h.includes('\t')){return true}
        let s = h.split('.');
        if(s.length === 1 || (s.length === 2 && s[1] === '')){
            return true
        }
        if(h.endsWith('.')){return true}
        try{
            let hh = 'http://' + h;
            let u = new URL(hh);
            if(u.origin !== hh){return true}
        }catch (e) {
            return true
        }
        return false
    }

    const err = badHostUri(hostUri);
    const errorPresent = props.error && props.error.length;
    useEffect(()=>{
        setLoading(false)
    },[props.error])

    return (
        <React.Fragment>
            <Title>
                Website domain
            </Title>
            <Grid   container
                    className={classes.centerVertically}
                    direction="column"
                    alignItems="center"
                    justifyContent="center">

                <Grid style={{display:'flex'}} item>
                    <TextField
                        error={errorPresent}
                        style={{marginRight:15}}
                        id="standard-helperText"
                        label="E.g. www.example.com"
                        helperText={errorPresent? props.error : "Only enter domain"}
                        variant="standard"
                        onChange={(e)=>{setHostUri(e.target.value)}}
                    />
                    {loading?
                        <CircularProgress style={{fontSize:15}}>foo </CircularProgress>
                        :
                        <Button
                            disabled={err}
                            variant="contained"
                            color="primary"
                            onClick={()=>{
                                setLoading(true)
                                props.onChange(hostUri).then(()=>{setLoading(false)})}}
                        >
                            {"Next"}
                        </Button>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}