import React, {Fragment, useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Title from "../Title";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Popover from "@material-ui/core/Popover";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const useStyles = makeStyles((theme) => ({
    buttons: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    centerVertically:{
        margin:'auto'
    }
}));

export default function CopyCode(props) {
    const classes = useStyles();
    const [attachedImage, setAttachedImage] = React.useState();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <React.Fragment>
            <Title>
                Copy code
            </Title>
            <Typography style={{marginBottom:5}}>
                Copy this snippet and paste it below your website's {`<head>`} tag. <br/> {!(props.meta) && "It should be right below the <meta> tag you just placed"}
            </Typography>
            <Grid   container
                    className={classes.centerVertically}
                    direction="column"
                    alignItems="center"
                    justifyContent="center">

                <Grid style={{display:'flex'}} item>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Grid style={{display:'flex'}} item>
                            <Typography style={{padding:20}} sx={{ p: 2 }}>Copied to clipboard</Typography>
                            <CheckBoxIcon style={{fontSize:55, color:'green'}}/>
                        </Grid>

                    </Popover>
                    <Button style={{marginRight:10}} aria-describedby={id}  onClick={(e)=>{
                        try{
                            let range = document.createRange();
                            range.selectNode(document.getElementById("code-website-block-to-copy"));
                            window.getSelection().removeAllRanges(); // clear current selection
                            window.getSelection().addRange(range); // to select text
                            document.execCommand("copy");
                            window.getSelection().removeAllRanges();// to deselect
                            handleClick(e)
                        }catch (e) {
                        }
                    }} variant="outlined" startIcon={<ContentCopyIcon />}>
                        Copy
                    </Button>
                    <code style={{width:'40vw',fontSize:'0.9em', maxWidth:650}} id={'code-website-block-to-copy'}>
                        {props.meta?
                            `<meta name=\"notifycam-site-verification\" content=\"${props.code}\" />`
                            :
                            `<script async data-id=\"${props.code}\" src=\"https://cdn.notifycam.com/js/notifycam.js\" crossorigin=\"anonymous\"></script>`
                        }
                    </code>

                    {(props.disableNext) ?
                        <Button
                            variant="contained"
                            color="primary"
                            href={'/'}
                            component={'a'}
                        >
                            {"Home"}
                        </Button>
                        :
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={()=>{props.handleNext();}}
                    >
                        {"Next"}
                    </Button>
                    }


                </Grid>
            </Grid>
        </React.Fragment>
    );
}