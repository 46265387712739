/* eslint-disable no-use-before-define */
import React, {useEffect, useState} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {autocompleteManager} from "../util/apiRequest";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 450,
        maxWidth:'60vw',
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
        color: 'inherit',
    }
}));

export default function ObjectComplete(props) {
    const classes = useStyles();
    const [topEvent, setTopEvent] = useState([])
    const [loading, setLoading] = useState(true);
    const [readyToRender, setReadyToRender] = useState(false);

    const [val, setVal] = useState(undefined);

    const [defaultValue, setDefaultValue] = useState();

    async function getTopEvents(){
        try{
            const topE = await autocompleteManager.get(window.localStorage.getItem("token"));//val
            setTopEvent(topE);
            setLoading(false);
        }
        catch (e) {
            console.error(e);
            setTopEvent([]);
            setLoading(false);
        }

    }

    useEffect(() => {
        try{
            setLoading(true)
            if(val){
                if(val.length >0){
                    (async () => {
                        const countries = await autocompleteManager.get(window.localStorage.getItem("token"),undefined,`?q=${val}`);
                        setLoading(false)
                        setTopEvent(countries);
                    })();
                }
            }
            else{
                (async () => {
                    const countries = await autocompleteManager.get(window.localStorage.getItem("token"),undefined,`?q=${val}`);

                    setLoading(false)
                    setTopEvent(countries);
                })();
            }
        }catch (e) {
            console.error(e);
            setLoading(false)
        }
    }, [val]);

    useEffect(() => {
        setLoading(true);
        if(props.loEventSelected && props.loEventSelected.length){
            setDefaultValue(props.loEventSelected);
            setReadyToRender(true)
        }
        else if(props.defaultValue){
            setDefaultValue(props.defaultValue);
            setReadyToRender(true)
        }
        else{
            if(props.ready){
                setReadyToRender(true)
            }
        }
        if(props.ready) {
            getTopEvents().catch(e => console.error("Fail to query autocomplete",e))
        }
    },[props.ready]);

    return (
        <div className={classes.root}>
            {(readyToRender && Array.isArray(topEvent)) &&
            <Autocomplete
                noOptionsText={"Try another keyword"}
                onInputChange={(e)=>{
                    if(e && e.target && e.target.value){
                        setVal(e.target.value);
                    }
                    else if(e.target.value === 0){
                        setVal(undefined);
                    }
                }}
                onChange={(event,value)=>{
                    props.onLoEventSelected(value);
                }}
                multiple
                limitTags={-1}
                id="multiple-limit-tags"
                loading={loading}
                getOptionSelected={(option, value) => {
                    return option === value
                }}
                options={topEvent}
                getOptionLabel={(option) => option || ""}
                defaultValue={defaultValue? defaultValue : undefined}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="" placeholder="Find keywords..." />
                )}
            />
            }

        </div>
    );
}
