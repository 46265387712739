import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Toolbar from "@material-ui/core/Toolbar";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import AddIcon from "@material-ui/icons/Add";
import Link from "@material-ui/core/Link";
// import {viewNotifications} from "../../../lib/clientUtil/apiManager";

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
    listRoot: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    seeMoreButton:{
        paddingRight:10,
        float:'right'
    }
}));

export default function SimplePopover(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    // const [notifications, setNotifications] = React.useState([{name:"Delivery Arrived at Home", time:new Date()},{name:"Mask Not Worn By Patrons!", time:new Date()}]);
    let notifications = props.notifications || [];

    function getBadgeNumber(loNote){
        if(loNote && loNote.length > 0){
            let unseen = loNote.filter(r=>!r.seen)
            if(unseen.length >0){
                if(unseen.length===3){
                    return "3+"
                }
                return unseen.length.toString()
            }
        }
        return undefined
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        const markAsSeen = notifications.filter((n)=>!n.seen);
        if(markAsSeen.length){
            // viewNotifications({seenNotifications:notifications}).then(r => {
            //     console.log('viewed them');
            //     props.setLoStreamNote(r.notifications)
            // })
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton aria-describedby={id} onClick={handleClick} color="inherit">
                <Badge badgeContent={getBadgeNumber(notifications)} color="secondary">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <List className={classes.listRoot}>
                    {
                        notifications &&
                            notifications.map((r,idx)=>{
                                return(
                                    <ListItem key={idx}>
                                        <ListItemText primary={r.event_name} secondary={new Date(r.createdAt).toLocaleString('en-US', { hour12: true })}/>
                                    </ListItem>
                                )
                            })
                    }
                </List>
                {notifications && notifications.length>0?
                    <Link className={classes.seeMoreButton} color="primary" href={'/notification/all'} >
                        See more
                    </Link>
                    :
                    <Typography  >
                        No notifications
                    </Typography>
                }

            </Popover>
        </div>
    );
}