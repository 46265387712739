import React, {Fragment, useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from "@material-ui/core/Button";
import {alpha, makeStyles} from "@material-ui/core/styles";
import Title from "../Title";
import Tabs from "@material-ui/core/Tabs";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import EmailIcon from '@material-ui/icons/Email';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import WifiIcon from '@material-ui/icons/Wifi';
import Box from "@material-ui/core/Box";
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from '@material-ui/icons/Info';
import {Hidden} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
const useStyles = makeStyles((theme) => ({
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    backButton:{
        marginLeft:5
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        borderColor: 'gray',
        border:'2px solid'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '20ch',
            '&:focus': {
                width: '22ch',
            },
        },
    },
}));

function a11yProps(index) {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}
function getPlaceHolder(i){
    switch (i) {
        case 0:
            return 'e.g. +1 123 456 7789';
        case 1:
            return 'e.g. youremail@domain.com'
        case 2:
            return 'e.g. https://example.com/...'
    }
}
function getMethodFromIndex(i){
    switch (i) {
        case 0:
            return 'phone';
        case 1:
            return 'email'
        case 2:
            return 'http'
    }
}

function getIndexFromMethod(i){
    switch (i) {
        case 'phone':
            return 0;
        case 'email':
            return 1
        default:
            return 2
    }
}

let codeStyle = {
    padding: "5px",
    margin: 0,
    borderRadius: '0 0 2px 2px',
    borderTop: 'solid 1px gray',
    font: 'monospace',

};

function getBadInputMsg(method){
    if(method.includes('phone')){
        return "Please check the number and try again"
    }
    else if(method.includes('email')){
        return "Please check the email and try again"
    }
    else if(method.includes('http')){
        return "Please check the URL and try again."
    }
    else{
        console.error("Method icon undefined:",method);
        return "Something went wrong :("
    }
}

function getMethodIcon(method){
    if(method.includes('phone')){
        return <PhoneIphoneIcon/>
    }
    else if(method.includes('email')){
        return <EmailIcon/>

    }
    else if(method.includes('http')){
        return <WifiIcon/>

    }
    else{
        console.error("Method icon undefined:",method)
    }
}

function isValidHttpUrl(string) {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}
const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};
const validatePN = (pn) => {
    return String(pn)
        .toLowerCase()
        .match(
            /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g
        );
};
function getTarget(textValue, method){
    let _target = textValue;
    if(method === 'phone'){
        _target = 'tel:' + textValue;
    }else if (method === 'email'){
        _target = 'mailto:' + textValue;
    }
    return _target
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const [textValue,setTextValue]=useState(props.text || "");
    const [method,setMethod]=useState(getMethodFromIndex(props.index));
    const target = getTarget(textValue, method);
    const [red,setRed]=useState(false);
    const [checked,setChecked]=useState(false);
    const [idxOfOld,setIdxOfOld]=useState(-1);

    useEffect(()=>{
        // Determine if it includes UTM provided by us by default
        if(props.text){
            try{
                let utm = applyUTM('');
                if(props.text.endsWith(utm.replace('?',''))){
                    setIdxOfOld(props.text.indexOf(utm.replace('?','')));
                    setChecked(true);
                }
            }catch (e) {
            }
        }
        setTextValue(props.text || "")
    },[value, index])

    const applyUTM = (uri) =>{
        if(!uri.endsWith('?')&&!uri.includes('?')){
            uri+='?'
        }
        let amper = uri.endsWith('&')?'':'&';
        let kw = (props.loEventSelected || []).join('+');
        return `${uri}${amper}utm_medium=cpc&utm_source=notifycam&utm_term=${encodeURIComponent(kw).replaceAll('%2B','+')}` // Remove garbage chars except +
    }

    const onChange=(e)=>{
        props.notifyChange && props.notifyChange(e);
        let _red = false;
        let txt = e.target.value;
        if(method === 'phone'){
            _red = !validatePN(txt)
        }else if (method === 'email'){
            _red=!validateEmail(txt)
        } else {
            _red = !isValidHttpUrl(txt);
        }
        setRed(_red)
        setTextValue(txt);
    }

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {(method === 'phone') &&
                    <Typography style={{marginBottom:4, fontSize:'0.68em'}}>Only accepting +1 country code </Typography>
                    }
                    <TextField
                        style={{marginRight:5, marginBottom:5, }}
                        error={props.message && props.message.length}
                        FormHelperTextProps={{error: true}}
                        helperText={(() => {
                            if ( props.message && props.message.length) {
                                return props.message;
                            }
                            return null;
                        })()}
                        value={textValue} id="outlined-basic" onChange={onChange} placeholder={getPlaceHolder(index)} label={props.label} variant="outlined" />
                    <Button disabled={red || !(textValue && textValue.length > 0)} style={{paddingTop:"1.0em",paddingBottom:"1.0em"}} color="primary" variant={'contained'} onClick={props.addEndpoint}>Next</Button>
                    <IconButton  disabled={!(target && textValue && textValue.length > 0)} style={{paddingTop:"1.0em",paddingBottom:"1.0em", color:textValue?'blue':'gray',fontSize:'0.8em'}} color="primary" variant={'outlined'}>
                        <a target="_blank" rel="noopener noreferrer" style={{color:'inherit',fontSize:'inherit'}} href={target}>Preview click<OpenInNewIcon/></a>
                    </IconButton>
                    {index===2 &&
                    <div style={{whiteSpace:'normal'}}>
                        <Typography style={{marginTop:15,marginBottom:15}} variant="body2" gutterBottom>
                            <FormControlLabel control={
                                <Checkbox
                                    disabled={!isValidHttpUrl(textValue)}
                                    color="primary"
                                    checked={checked}
                                    onChange={(e)=>{
                                        if(checked){
                                            setChecked(false);
                                            const withoutUTM = textValue.slice(0,idxOfOld);
                                            setTextValue(withoutUTM);
                                            setIdxOfOld(-1);
                                            onChange({target:{value:withoutUTM}});
                                        } else {
                                            if(isValidHttpUrl(textValue)){
                                                const withUtm = applyUTM(textValue);
                                                setIdxOfOld(textValue.length);
                                                setTextValue(withUtm);
                                                setChecked(true);
                                                onChange({target:{value:withUtm}});
                                            }
                                        }
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            } label="Automatically ad UTM campaign fields" />

                        </Typography>
                    </div>
                    }
                </Box>
            )}
        </div>
    );
}


export default function AddNotificationEndpoints(props) {
    const [message,setMessage]=useState('');
    const [storeEndpoint,setStoreEndpoint] = useState({method:props.method || "http",endpoint:props.endpoint});
    const [value, setValue] = React.useState(getIndexFromMethod(props.method));

    const changeCurrentEndpoint = (method, endpoint) => {
        const original = method === props.method? props.endpoint: null;
        setStoreEndpoint({method:method, endpoint:endpoint || original});
    };

    const handleChange = (event, newValue) => {
        setMessage('');
        const original = getMethodFromIndex(newValue) === props.method? props.endpoint: null;
        setStoreEndpoint((ps)=>({method:getMethodFromIndex(newValue),endpoint:original}));
        setValue(newValue);
    };

    const addEndpoint = (e) =>{
        props.onChange(storeEndpoint)
        props.handleNext()
    }

    return (
        <React.Fragment>
            <Title>
                Clicking the ad will prompt this call to action
            </Title>
            <Paper square>
                <Grid   container
                        direction="column"
                        alignItems="center"
                        justify="center">
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="Select notification endpoint"

                >
                    <Tab style={{width:'33%'}} icon={getMethodIcon("phone")} aria-label="phone" label="phone number" {...a11yProps(0)}/>
                    <Tab style={{width:'33%'}} icon={getMethodIcon("email")} aria-label="email" label="email" {...a11yProps(1)} />
                    <Tab style={{width:'33%'}} icon={getMethodIcon("http")} aria-label="link" label="link" {...a11yProps(2)} />
                </Tabs>
                <TabPanel text={getMethodFromIndex(0) === props.method? props.endpoint: undefined} message={message} addEndpoint={addEndpoint}  notifyChange={(v)=>{changeCurrentEndpoint(getMethodFromIndex(0),v.target.value)}} label="phone number" value={value} index={0}>
                </TabPanel>
                <TabPanel text={getMethodFromIndex(1) === props.method? props.endpoint: undefined} message={message} addEndpoint={addEndpoint}  notifyChange={(v)=>{changeCurrentEndpoint(getMethodFromIndex(1),v.target.value)}}  label="email"  value={value} index={1}>
                </TabPanel>
                <TabPanel loEventSelected={props.loEventSelected} text={getMethodFromIndex(2) === props.method? props.endpoint: undefined} message={message} addEndpoint={addEndpoint}  notifyChange={(v)=>{changeCurrentEndpoint(getMethodFromIndex(2),v.target.value)}}  label={"link"} value={value} index={2}>
                </TabPanel>
                </Grid>
            </Paper>
        </React.Fragment>
    );
}