import React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  DialogTitle,
  Typography,
  Box,
  withTheme
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {makeStyles} from "@material-ui/core/styles";
import * as CONSTANTS from "../../config/CONSTANTS";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({

    imgIcon:{
      marginRight:6,
        paddingBottom:12,
          verticalAlign:'middle',
      [theme.breakpoints.down('sm')]: {
            width:95,
            height:95,
      },
      [theme.breakpoints.up('md')]: {
        width:100,
            height:100,

      },
      [theme.breakpoints.up('lg')]: {
        width:100,
            height:100,

      },
    },
    customHeader:{

      [theme.breakpoints.down('sm')]: {
        fontSize:"1.75rem",
      },
      [theme.breakpoints.up('md')]: {
        fontSize:"2.0rem",
      },
      [theme.breakpoints.up('lg')]: {
        fontSize:"2.5rem",
      },
      // fontSize:"5.0rem",
      fontWeight:"700",
        marginBottom:0,
    },
}));



function FormDialogTitle(props) {
    const classes = useStyles()

    const {
    paddingBottom,
    onClose,
    disabled,
    title,
    disablePadding
  } = props;
  return (
    <DialogTitle
      style={{
        paddingLeft: disablePadding ? 0 : null,
        paddingRight: disablePadding ? 0 : null,
        paddingTop: 0,
          paddingBottom: 0,

          width: "100%"
      }}
      disableTypography
    >
      <Box display="flex" justifyContent="space-between">
        <Typography className={classes.customHeader} component="h5" variant="h5" align="left" color="textPrimary" gutterBottom>
          <img className={classes.imgIcon} src="/favicon.svg"  alt="Notifycam logo"/>
          {title}
        </Typography>
      </Box>

    </DialogTitle>
  );
}


export default withTheme(FormDialogTitle);
