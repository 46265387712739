import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import {CardContent, CardHeader, CircularProgress} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {paymentManager} from "../../util/apiRequest";
// import {fetchPlan} from "../../../../lib/clientUtil/apiManager";

const useStyles = makeStyles((theme) => ({
    creditCard:{
        fontSize:"12em",
        color:theme.palette.primary.dark
    },
    root:{
        maxWidth:600
    }
}
));



function UpdateCard(props){
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [brand, setBrand] = useState();
    const [last4, setLast4] = useState();

    const getPlans = () =>{
        return paymentManager.get(window.localStorage.getItem("token"),undefined,'/customer').then(r=>{
            if(r.card){
                setBrand(r.card.cardBrand);
                setLast4(r.card.cardLast4);
                setLoading(false)
            }
            setLoading(false)
        })
    }

    useEffect(()=>{
        getPlans()
    },[]);

    return (
        <Container {...props} maxWidth="md" component="footer">
            {!loading?
                <Card className={classes.root}>
                    <CardContent>
                        <CreditCardIcon className={classes.creditCard}/>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                Default payment method
                            </Typography>
                            <Typography style={{textTransform:'uppercase'}} variant="body2" color="textSecondary" component="p">
                                {brand}
                                <code style={{marginLeft:10}}>
                                {"****-****-****-"} {last4}
                                </code>
                            </Typography>

                        </CardContent>
                    </CardContent>
                    <CardActions>
                        <Button style={{marginLeft:15}} component='a' href='/card?update=true' size="small" color="primary">
                            Update
                        </Button>

                    </CardActions>
                </Card>

                :
                <Grid container fullWidth spacing={2} justify="center">
                    <Grid item>
                        <CircularProgress style={{}} size={28} />
                    </Grid>
                </Grid>

            }


        </Container>
    )
}



export default UpdateCard;
