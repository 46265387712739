import React, {Fragment, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PaymentMethod from '../components/PaymentMethod';
import { Elements } from '@stripe/react-stripe-js';
import getStripe from '../util/stripe/get-stripejs';
import Copyright from "../components/Copyright";
import * as CONSTANTS from "../config/CONSTANTS";
import Hidden from "@material-ui/core/Hidden";
import CheckIcon from '@material-ui/icons/Check';
import IconButton from "@material-ui/core/IconButton";
import {EMAIL} from "../config/CONSTANTS";
import {paymentManager} from "../util/apiRequest";
import {CircularProgress} from "@material-ui/core";
import {useNavigate} from "react-router-dom";
const myStripe = getStripe();


const useStyles = makeStyles((theme) => ({
    root:{
        backgroundColor:theme.palette.background.appGray,
        minHeight:'100vh',
        height:'100%'
    },
    copyRight:{
        backgroundColor:theme.palette.background.appGray,
        height:'100%',
        minHeight:'100vh',

    },
    appBar: {
        position: 'relative',
        color:'#fff',
        backgroundColor: '#3b5ea8ff'//theme.palette.primary

    },
    bannerBar:{
        position: 'relative',
        color:'#fff',
        backgroundColor: 'rgba(59,168,134,0.1ls)'//theme.palette.primary
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    backButton:{
        marginTop: theme.spacing(3),
        marginRight: "auto",
    },
    imgIcon: {
        marginTop:0,
        marginBottom:0,
        paddingTop:0,
        paddingBottom:0,
    },
    title: {
        flexGrow: 1,
    },
}));



const getSkuAmount = (sku, pricingPlans)=>{
    return pricingPlans.filter(pp => pp.title === sku)[0].amount;
}
const getLabelForSku = (sku, pricingPlans)=>{
    if(sku === 'Enterprise'){
        return sku + " plan - contact sales: "+EMAIL
    }
    let amount = getSkuAmount(sku, pricingPlans);
    amount = (amount/100).toFixed(2).toString();
    return sku + ' plan - $' + amount  + ' /mo'
}

function Card(props) {
    const classes = useStyles();

    const [sku,setSku] = useState();
    const [skuAmount,setSkuAmount] = useState();
    const [skuLabel,setSkuLabel] = useState();
    const [loading,setLoading] = useState(true);
    const [clientSecret,setClientSecret] = useState();
    const [somethingWentWrong,setSomethingWentWrong] = useState();
    const [pricingPlans,setPricingPlans] = useState();
    const [update,setUpdate] = useState(false);
    const [customerVerify,setCustomerVerify] = useState(false);
    let navigate = useNavigate();

    useEffect(()=>{
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const doUpdate = params && params.update && params.update.toLowerCase().toString()==='true';
        paymentManager.get(window.localStorage.getItem("token"),undefined,doUpdate? '/update':undefined).then(r=>{
            if(r.status_code === 200 && r.client_secret){
                setClientSecret(r.client_secret)
                setPricingPlans(r.pricingPlans)
                setUpdate(r.update);
                setCustomerVerify(r.customer_verify);

            } else {
                setSomethingWentWrong(true)
            }
            setLoading(false)
        }).catch(e=>{
            console.error(e);
            if(e && e.response && e.response.status === 301 && e.response.data && e.response.data.redirect_uri){
                navigate(e.response.data.redirect_uri)
            }
            setSomethingWentWrong(true)
            setLoading(false)
        })
    },[])

    return (
        <div className={classes.root}>
            <Elements stripe={myStripe}>

            <CssBaseline />
            <AppBar position="absolute" color="default" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        href='/'
                        className={classes.imgIcon}
                    >
                        <img className={classes.imgIcon} src="/favicon.svg" width={40} height={40} alt="Notifycam logo"/>
                    </IconButton>
                    {/*</Hidden>*/}
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        <a href='/card' style={{textDecoration:'none',color:'#e6e6e6ff'}}>
                            {CONSTANTS.APP_NAME}
                        </a>
                    </Typography>
                </Toolbar>
                {/*<Toolbar className={classes.bannerBar}>*/}
                {/*    <Typography variant="h6" color="inherit" noWrap>*/}
                {/*        Add your card to start your one month free trial*/}
                {/*    </Typography>*/}
                {/*</Toolbar>*/}
            </AppBar>
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Hidden xsDown>
                        <Typography style={{marginBottom:5}} component="h1" variant="h4" align="left">
                            {update?
                                customerVerify?
                                    "Verify your payment information"
                                    :
                                    "Update your card"
                            :
                            (sku === 'Basic') ?
                                    "Start your 1 month free trial"
                                    :
                                    "Get started today"
                            }

                        </Typography>
                        <Typography style={{marginBottom:10}}  align="left">
                            <CheckIcon style={{color:'green',marginBottom:-5}}/>
                            Cancel anytime
                        </Typography>
                    </Hidden>
                    <Hidden smUp>
                    <Typography style={{fontSize:"1.5em", fontWeight:'bold'}} component="h1" variant="h4" align="left">
                        {update?
                            customerVerify?
                                "Verify your payment information"
                                :
                                "Update your card"
                            :
                            (sku === 'Basic') ?
                                "Start your 1 month free trial"
                                :
                                "Get started today"
                        }
                    </Typography>
                    <Typography style={{marginBottom:10,marginTop:5}}  align="left">
                        <Fragment>
                            <CheckIcon style={{color:'green',marginBottom:-5}}/>
                            Cancel anytime
                        </Fragment>
                    </Typography>
                    </Hidden>
                    {loading ?
                        <CircularProgress size={14} />
                        :
                        somethingWentWrong?
                            <Typography>Something went wrong. Please refresh the page</Typography>
                            :
                            <PaymentMethod update={update} customer_verify={customerVerify} getLabelForSku={getLabelForSku} sku={sku} skuAmount={skuAmount} skuLabel={skuLabel} pricingPlans={pricingPlans} clientSecret={clientSecret} {...props} />
                    }
                </Paper>
                <Copyright className={classes.copyRight} />
            </main>
            </Elements>
        </div>
    );
}

// export const getServerSideProps = async (rec) => {
//     return {props:{ update:rec.query.update? true:false, csrfToken: rec.query.csrfToken, customer_verify:rec.query.customer_verify? true:false, returnUri:rec.query.returnUri, client_secret:rec.query.client_secret, pricingPlans:rec.query.pricingPlans }}
// }

export default Card;
