import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Avatar from '@material-ui/core/Avatar';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { Theme } from '@material-ui/core';
import {Add} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import { useTheme } from "@material-ui/core/styles";


// export const gmailButtonStyles = ({ palette }) => {
//     return {
//         root: ({ collapsed }) => ({
//             minWidth: collapsed ? 56 : 64,
//             minHeight: collapsed ? 56 : 48,
//             backgroundColor: palette.common.white,
//             padding: `8px ${collapsed ? '8px' : '24px'} 8px ${
//                 collapsed ? '8px' : '16px'
//             }`,
//             borderRadius: 40,
//             boxShadow:
//                 '0 1px 2px 0 rgba(60,64,67,0.302), 0 1px 3px 1px rgba(60,64,67,0.149)',
//             '&:hover': {
//                 boxShadow:
//                     '0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149)',
//                 backgroundColor: '#fafafb',
//             },
//             '&:active': {
//                 backgroundColor: '#f1f3f4',
//             },
//         }),
//         label: {
//             fontFamily:
//                 "'Google Sans', Roboto,RobotoDraft,Helvetica,Arial,sans-serif",
//             color: '#3c4043',
//             textTransform: 'none',
//             fontWeight: 500,
//         },
//         img: {
//             width: 32,
//             height: 32,
//         },
//         startIcon: ({ collapsed }) => ({
//             margin: collapsed ? 0 : '',
//         }),
//     };
// };
//
// const useStyles = makeStyles(gmailButtonStyles, { name: 'GmailButton' })
//
// const GmailButton = ({ collapsed, classes, ...props }) => {
//     const styles = useStyles({ collapsed, ...props })
//     const { img: imgClassName, ...buttonClasses } = styles;
//     return (
//         <Button
//             disableRipple
//             {...props}
//             classes={buttonClasses}
//             startIcon={
//                 <Avatar
//                     className={imgClassName}
//                     src={
//                         'https://www.gstatic.com/images/icons/material/colored_icons/2x/create_32dp.png'
//                     }
//                 />
//             }
//         >
//             {!collapsed && 'Compose'}
//         </Button>
//     );
// }
const useStyles = makeStyles((theme) => ({
    root:{
        [theme.breakpoints.up('sm')]: {
            visibility:'hidden'
        },
        [theme.breakpoints.down('sm')]: {
            visibility:'visible'
        },
        color:theme.palette.primary,
        position:'fixed',
        bottom:10,
        right:10,
        backgroundColor:'white',
        boxShadow: '0 1px 2px 0 rgba(60,64,67,0.302), 0 1px 3px 1px rgba(60,64,67,0.149)',
        '&:hover': {
        boxShadow: '0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149)',
        backgroundColor: '#fafafb',
        },
        '&:active': {
        backgroundColor: '#f1f3f4',
        },
    }
}))
const HoverPlusButton = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const linkCreate = props.userType === 'Customer'? '/create': '/website/create'
    return (
        <IconButton component='a' onClick={()=>{
            window.location.href=linkCreate
        }} className={classes.root} >
            <Add  style={{fontSize:50, color:theme.palette.secondary.main}} />
        </IconButton>
    );
}

export default HoverPlusButton;