import * as React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import {BrowserRouter} from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import theme from './theme';
import Index from "./pages";
import Card from "./pages/card";
import Dashboard from './pages/dashboard';
import AddEvent from "./pages/addEvent";
import AddWebsite from "./pages/addWebsite";
import UserPage from "./pages/user";
import {AUTH_URL} from "./config/CONSTANTS";
import {Fragment} from "react";
import {userManager} from "./util/apiRequest";
import FindAccountTypeIntentModal from "./components/FindAccountTypeIntentModal";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";



const NApp = () => {
    const acquireLink= (token)=>{
        userManager.get(token,undefined,'/redirectUri').then((r)=>{
            if(r.status_code === 200){
                window.location.href = r.redirect_uri;
            }
        }).catch(e=>{console.error("Failed to acquire link",e)})
    }

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
    const target = getCookie('redirect');

    const removeHash = () => {
        try {
            history.pushState("", document.title, window.location.pathname
                + window.location.search);
        }catch (e) {}
    }

    let mightBeAuthenticated = (window.localStorage.getItem('token'));
    let visitStripe = false;

    if(!mightBeAuthenticated){
        let hash = window.location.hash;
        if(hash && hash.startsWith('#token=')){
            let fullUrl = window.location.href;
            let params = (new URL(fullUrl.replace('#token=','?token='))).searchParams;
            const paramToken = params.get('token');

            if(paramToken){
                mightBeAuthenticated = true;
                window.localStorage.setItem('token',paramToken)
                if(target === 'account'){
                    visitStripe = true;
                    acquireLink(paramToken)
                } else {
                    removeHash()
                    if(target === 'card'){
                        window.location.href='/card';
                    }
                }
            }
        }
    }

    if(visitStripe){
        return (
            <div>Loading...</div>
        )
    } else if(mightBeAuthenticated || window.location.pathname === "/asduihdf89kj"){
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <BrowserRouter>
                    <Routes>
                        <Route path="/asduihdf89kj" element={ <Index />} />
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/account" element={<Dashboard />} />
                        <Route exact path="/user" element={ <UserPage />} />
                        <Route exact path="/card" element={<Card />} />
                        <Route exact path="/create" element={<AddEvent />} />
                        <Route path="/edit" element={<AddEvent edit />} />
                        <Route exact path="/website/create" element={<AddWebsite />} />
                        <Route path="/website/edit" element={<AddWebsite hide={true} />} />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        )
    } else {
        // window.location.href = AUTH_URL

        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Container  style={{height:'100vh', marginTop:'5vh'}} maxWidth={'sm'}>
                    <Paper elevation={3}>
                        <FindAccountTypeIntentModal />
                    </Paper>
                </Container>
            </ThemeProvider>
        )
    }
}

ReactDOM.render(<NApp/>,
  document.querySelector('#root'),
);
