import React, {Fragment, useEffect, useState} from 'react';
import clsx from 'clsx';
import { makeStyles,alpha } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems, SecondaryListItems } from '../components/listItems';
import SimplePopover from '../components/PopOver';
import Title from "../components/Title";
import * as CONSTANTS from "../config/CONSTANTS";
import SearchEvents from "../components/AddEventSteps/SearchEvents";
import ChooseDevices from "../components/AddEventSteps/ChooseDevices";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
// import {fetchDevice, postDevice, postEvent, queryAutocomplete} from "../lib/clientUtil/apiManager";
import AddNotificationEndpoints from "../components/AddEventSteps/AddNotificationEndpoints";
import Copyright from "../components/Copyright";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {autocompleteManager, bidManager, edgeManager, userManager} from "../util/apiRequest";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import LinearSlider from "../components/Slider";
import UploadAd from "../components/AddEventSteps/UploadAd";
import Budget from "../components/AddEventSteps/Budget";
import {useNavigate} from "react-router-dom";
import {ID_SIZE} from "../config/CONSTANTS";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Hidden} from "@mui/material";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor:theme.palette.background.appGray

    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: "2%",
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        visibility:"visible",

    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
        [theme.breakpoints.down('sm')]: {
            width: 0,
            // visibility:"hidden",
            display:'none',
        },
        [theme.breakpoints.up('md')]: {
            width: theme.spacing(9),
            visibility:"visible",
        },
        [theme.breakpoints.up('lg')]: {
            width: theme.spacing(9),
            visibility:"visible",
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        // overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        minHeight: 500,
        height: "100%",

    },
    stepper:{
        marginLeft:0,
        paddingLeft:0,
        overflowX:'auto'
    },
    completed:{
        color:'#19857b !important'
    },


}));

const steps = ['Upload image', 'Keywords', 'Call to action','Budget'];



export default function AddEvent(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [eventData, setEventData] = React.useState({image:"",budget:0,action:"",source:"",loEventSelected:[],showOnlyInMyCity:false});
    let navigate = useNavigate();
    const [displayAppLogo, setDisplayAppLogo] = React.useState(true);
    const [readyToRenderAutocomplete, setReadyToRenderAutocomplete] = React.useState(!props.wait);
    const [editDeviceName, setEditDeviceName] = React.useState();
    const [error, setError] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const [done, setDone] = React.useState();
    const [imageAsUrl, setImageAsUrl] = React.useState(false);
    const [baseQuery, setBaseQuery] = React.useState('?');
    const [failureStep, setFailureStep] = React.useState('-1');

    const [token, setToken] = React.useState(window.localStorage.getItem("token"));
    const [userType, setUserType] = React.useState();

    useEffect(()=>{
        userManager.get(token,undefined,'/type').then(r=>{
            if(r.status_code === 200){
                setUserType(r.type)
            }
        }).catch(e=>{
            console.error("error",e);
        })
    },[])


    useEffect(()=>{
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if(typeof params.i === 'string' && typeof params.ad_id === 'string'){
            const low = params.ad_id.toLowerCase();
            if(!(low.includes('/') || low.includes('<') || low.includes('%') || low.includes('>') || low.includes('"') || low.length !== ID_SIZE)){
                const bq = `/update?idx=${params.i}&ad_id=${params.ad_id}&`;
                const bqFetch = `?idx=${params.i}&ad_id=${params.ad_id}&accept_image=true`;
                bidManager.get(window.localStorage.getItem("token"), undefined, bqFetch ).then(r => {
                    setBaseQuery(bq)
                    setLoading(false);
                    setImageAsUrl(r.bid.image64)
                    setEventData({loEventSelected:r.bid.keyword_all, source:r.bid.source, action:r.bid.action, budget:parseInt(r.bid.bid_cents)/100})
                }).catch(e=>{
                    console.error(e);
                })
            }
        } else {
            setLoading(false);
        }
    },[])

    const onLoEventSelected = (lo) => {
        setEventData({...eventData, loEventSelected:lo})
    };

    const showOnlyInMyCity = (v) => {
        setEventData({...eventData, showOnlyInMyCity:v})
    };
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const _postEvent = async (budget) => {
        setLoading(true);
        // let bodyFormData = new FormData();
        // bodyFormData.append('image', eventData.image);
        toBase64(eventData.image).then(image64=>{
            let data = {
                bid_cents:budget*100,
                source:eventData.source,
                action:eventData.action,
                keyword_all:eventData.loEventSelected,
                image:image64,
                width:eventData.width,
                height:eventData.height,
                showOnlyInMyCity:eventData.showOnlyInMyCity
            }
            edgeManager.unAuthGet('signature').then((em)=>{
                data.salt = em.data.signature.salt;
                data.fw = em.data.signature.fw;

                bidManager.post(window.localStorage.getItem("token"), data, baseQuery)
                    .then(r=>{
                        setLoading(false);
                        if(r.status_code === 201){
                            setDone(true)
                        } else {
                            const fp = r.failurePoint;
                            setFailureStep(fp);
                            setActiveStep(failureMap(fp))
                        }
                    }).catch(e=>{
                    console.error(e);
                    setLoading(false);
                    const fp = e.response.data.failurePoint || "image";
                    setFailureStep(fp);
                    setActiveStep(failureMap(fp))
                })

            }).catch(e=>{
                console.error(e);
                setLoading(false);
                const fp = "image";
                setFailureStep(fp);
                setActiveStep(failureMap(fp))
            })

        }).catch(e=>{
            console.error(e);
            setFailureStep("image");
            setActiveStep(failureMap("image"));
            setLoading(false);

        })

    };

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const isStepFailed = (step) => {
        return step === failureMap(failureStep);
    };

    const failureMap = (point) =>{
        switch (point) {
            case "image":
                return 0
            case "endpoint":
                return 1
            case "keyword":
                return 2
            case "budget":
                return 3
            default:
                return -1
        }
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <UploadAd img64={imageAsUrl} editimg={eventData.image} handleBack={handleBack} handleNext={handleNext} onChange={(u, w, h)=>{setEventData({...eventData, image:u, width:w, height:h })}} activeStep={activeStep}/>;
            case 1:
                return <SearchEvents defaultCheckedValue={eventData.showOnlyInMyCity} showAdInMyCity={showOnlyInMyCity} defaultValue={eventData.loEventSelected} max_events_per_device={15} editMode={false} ready={readyToRenderAutocomplete} loEventSelected={eventData.loEventSelected}  handleBack={handleBack} handleNext={handleNext} activeStep={activeStep} onLoEventSelected={onLoEventSelected} />;
            case 2:
                return <AddNotificationEndpoints loEventSelected={eventData.loEventSelected} method={eventData.action} endpoint={eventData.source} handleBack={handleBack} onChange={(u)=>{setEventData({...eventData, action:u.method,  source:u.endpoint })}} handleNext={handleNext} activeStep={activeStep}/>; // Display multi-item autocomplete if no event set. Add devices to notify, test notification, double rate limit (2 every minute logged in and paying),
            case 3:
                return <Budget budget={eventData.budget} updateBudget={(b)=>{setEventData({...eventData, budget:b})}} onFinish={_postEvent}/>
            default:
                throw new Error('Unknown step');
        }
    }

    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    {displayAppLogo &&
                    <React.Fragment>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            href='/'
                            className={classes.imgIcon}
                        >
                            <img className={classes.imgIcon} src="/favicon.svg" width={40} height={40}
                                 alt="Notifycam logo"/>
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            <a href='/' style={{textDecoration: 'none', color: 'inherit'}}>
                                {CONSTANTS.APP_NAME}
                            </a>
                        </Typography>
                    </React.Fragment>
                    }
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <Typography>
                        Welcome {props.first_name}
                    </Typography>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>{mainListItems}</List>
                <Divider />
                <List><SecondaryListItems/></List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container justify="center" alignItems="center" spacing={3}>
                        {/* Chart */}
                        <Grid item xs={12} md={8} lg={9}>
                            <Paper className={fixedHeightPaper}>
                                <Title>
                                    {props.edit? `Edit advertisement`:"Create advertisement"}
                                </Title>
                                {done?
                                    <Button style={{marginTop:'20%'}} onClick={()=>{navigate('/')}}> <ArrowBackIcon/>View on Dashboard</Button>
                                    :
                                    <Fragment>
                                            <Stepper activeStep={activeStep} className={classes.stepper}>
                                                {
                                                    steps.map((label, index) => {
                                                        const labelProps = {};
                                                        if (isStepFailed(index)) {
                                                            labelProps.optional = (
                                                                <Typography variant="caption" color="error">
                                                                    Please check your {index === 0? " image": label.toLowerCase()}
                                                                </Typography>
                                                            );
                                                            labelProps.error = true;
                                                        }
                                                        return (
                                                            <Step key={label}>
                                                                <StepLabel  {...labelProps}
                                                                    StepIconProps={{classes: {completed: classes.completed}}}>{label}</StepLabel>
                                                            </Step>
                                                        )
                                                    })
                                                }
                                                <br/>
                                            </Stepper>
                                        {error?
                                        <Typography>{error}</Typography>
                                            :
                                            loading? <CircularProgress  size={64}/>  : getStepContent(activeStep)
                                        }
                                    </Fragment>
                                }
                                <div className={classes.buttons} style={{marginTop:'auto'}}>
                                    {((activeStep !== 0) && (!done)) && (
                                        <Button onClick={handleBack} className={classes.button}>
                                            <ArrowBackIcon/>
                                        </Button>
                                    )}
                                </div>
                            </Paper>

                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Copyright />
                    </Box>
                </Container>
            </main>
        </div>
    );
}

// AddEvent.getInitialProps = async (rec) => {
//     return {wait:rec.query.wait, q:rec.query.q, csrfToken: rec.query.csrfToken,
//         max_notifications_per_month:rec.query.max_notifications_per_month,
//         max_events_per_device:rec.query.max_events_per_device,
//         first_name:rec.query.first_name, email:rec.query.email, device_id: rec.query.device_id}
// }
