import React, {useEffect, useState} from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Title from './Title';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from "@material-ui/core/Popover";
import DevicesIcon from '@material-ui/icons/Devices';
import AddIcon from '@material-ui/icons/Add';
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CreateIcon from '@material-ui/icons/Create';
import RemoveDeviceModal from "./RemoveDeviceModal";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {bidManager, hostManager} from "../util/apiRequest";
import ShieldIcon from '@mui/icons-material/Shield';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import VerifyModal from "./VerifyModal";
import Typography from "@material-ui/core/Typography";
import ListItemButton from "@mui/material/ListItemButton";

const useStyles = makeStyles((theme) => ({
    depositContext: {
        flex: 1,
    },
    listRoot: {
        width: '100%',
        maxWidth: 500,
        overflowY:"visible",
        overflow:"-moz-scrollbars-vertical"
    },
    nested: {
        paddingLeft: theme.spacing(6),
    },
    noButtonBehaviour:{
        "&:hover": {
            backgroundColor: 'transparent'
        }
    }
}));

function DeviceEventList(props) {
    const [evList,setEvList] = useState();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClick = (e) => {
        e.stopPropagation();
        if(!(evList && evList.length)){
        }
        setOpen(!open);
    };

    const refreshEvents = () =>{
        bidManager.get(props.token, undefined, `?idx=${props.device_id}&ad_id=${props.ad_id}`).then(r => {
            setEvList(r.bid.keyword_all);
        }).catch(e=>{
            console.error(e);
            setEvList([])
        })
    }
    useEffect(() => {
        if(props.type === 'Customer'){
            refreshEvents()
        }
    },[]);
    return (
        <React.Fragment>
            <ListItem button onClick={handleClick}>
                    <IconButton aria-label="events">
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon/>}
                    </IconButton>
                <ListItemText primary={(props.type === 'Customer')?"Attached keywords":"View id"}/>

            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {((props.type !== 'Customer') && props.website_id) &&
                <ListItem className={classes.nested}>
                    {props.website_id}
                </ListItem>
                }
            {evList &&
                <List component="div" disablePadding>
                    {evList.map((r, idx) => {
                        return (
                            <ListItem className={classes.nested} key={idx}>
                                {r}
                            </ListItem>
                        )
                    })}
                </List>
            }
                {(!evList && (props.type === 'Customer')) &&
                <CircularProgress fontSize={2} />
                }
            </Collapse>
        </React.Fragment>
            )
}

export default function DeviceList(props) {
    const classes = useStyles();
    const [devList,setDevList] = useState(props.websites || props.ads || []);
    const [anchorMap, setAnchorMap] = React.useState({});
    const [openRemove, setOpenRemove] = React.useState(false);
    const [highlightIdx, setHighlightIdx] = React.useState(0);
    const [loadingIdxLo, setLoadingIdxLo] = React.useState([]);
    const [failIdxLo, setFailIdxLo] = React.useState([]);
    const [verifyStates, setVerifyStates] = React.useState(props.verifyStates);

    useEffect(()=>{
        setVerifyStates(props.verifyStates)
    },[props.verifyStates])

    useEffect(()=>{
        setDevList(props.websites || props.ads || [])
    },[props.websites,props.ads])

    const handleClick = (event, id) => {
        setAnchorMap((prevState) => {
            return {...prevState, [id]:event.currentTarget}
        })
    };

    const handleClose = (id) => {
        setAnchorMap((prevState) => {
            return {...prevState, [id]:null}
        })
    };

    const toggleDeviceState = (dIdx)=>{
        setHighlightIdx(dIdx);
        setDevList(devList);
    }

    const editName =(props.type === 'Customer')? "Edit": "Copy script";
    return (
        <React.Fragment>
            <Title>
                {(props.type === "Customer")? "Ads": "Websites"}
            </Title>
            {props.displayPlus &&
            <div>
                    <Button startIcon={<AddIcon color="primary" /> }>
                        <Link color="primary" href={'/event/add'} >
                        Add Device
                        </Link>
                    </Button>
            </div>
            }
            <List className={classes.listRoot} component="nav" aria-label="main mailbox folders">
                {
                    devList.map((r,idx)=>{
                        return(
                            <ListItem
                                onClick={(e)=>{e.preventDefault();e.stopPropagation();props.onOptionClick(idx);toggleDeviceState(idx)}}
                                style={(idx===highlightIdx)? {borderColor:'yellow',borderStyle:'solid',borderWidth:4}:{}}
                                      key={idx} button>
                                <Tooltip title="Device options" disableFocusListener>

                                <IconButton
                                    aria-describedby={Boolean(anchorMap[idx]) ? 'simple-popover' : undefined}
                                    onClick={(e)=>{e.preventDefault();e.stopPropagation();handleClick(e,idx)}}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                </Tooltip>
                                <IconButton className={classes.noButtonBehaviour}>
                                    <DevicesIcon/>
                                </IconButton>
                                <Popover
                                    id={Boolean(anchorMap[idx]) ? 'simple-popover' : undefined}
                                    open={Boolean(anchorMap[idx])}
                                    anchorEl={anchorMap[idx]}
                                    onClose={(e)=>{e.stopPropagation();handleClose(idx)}}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <List className={classes.listRoot}>
                                        <ListItemButton onClick={()=>{window.location.replace((props.type==='Customer')? `/edit/?i=${idx}&ad_id=${props.ads[idx]}`: `/website/edit/?i=${idx}&website_id=${props.website_ids[idx]}`)}} >
                                            <Tooltip title={editName} disableFocusListener>
                                                {(props.type==='Customer')?
                                                    <CreateIcon style={{marginRight:4, color:'gray'}}/>
                                                    :
                                                    <OpenInNewIcon style={{marginRight:4, color:'gray'}}/>
                                                }
                                            </Tooltip>
                                            <ListItemText primary={editName}/>
                                        </ListItemButton>
                                        {(props.type === 'Account') &&
                                        <ListItemButton onClick={() => {
                                            window.location.replace(`/website/edit/?i=${idx}&website_id=${props.website_ids[idx]}&verify=true`)
                                        }} >
                                            <Tooltip title={"Copy site verification"} disableFocusListener>
                                                    <ShieldIcon style={{marginRight:4, color:'gray'}}/>
                                            </Tooltip>
                                            <ListItemText primary={"Copy site verification"}/>
                                        </ListItemButton>
                                        }
                                        <ListItemButton
                                            onClick={(e)=>{e.stopPropagation();setOpenRemove(true)}}
                                        >
                                            <Tooltip title="Delete" disableFocusListener>
                                                    <DeleteIcon style={{marginRight:4, color:'gray'}} />
                                            </Tooltip>
                                            <RemoveDeviceModal type={props.type} open={openRemove} handleClose={(e)=>{e.preventDefault();e.stopPropagation();setOpenRemove(false)}} performAction={()=>{
                                                if(props.type==='Customer'){
                                                    bidManager.del(props.token, undefined, `?idx=${idx}&ad_id=${props.ads[idx]}`).then(res => {
                                                        window.location.href=window.location.href;
                                                        setOpenRemove(false);
                                                    }).catch(e=>{
                                                        console.error(e);
                                                        setOpenRemove(false);
                                                    })
                                                } else{
                                                    hostManager.del(props.token, undefined, `?idx=${idx}&host_id=${props.website_ids[idx]}`).then(res => {
                                                        window.location.href=window.location.href;
                                                        setOpenRemove(false);
                                                    }).catch(e=>{
                                                        console.error(e);
                                                        setOpenRemove(false);
                                                    })
                                                }
                                            }}/>
                                            <ListItemText primary="Delete"/>
                                        </ListItemButton>
                                        <DeviceEventList token={props.token} type={props.type} ad_id={props.ads && props.ads[idx]} device_id={idx} website_id={props.website_ids && props.website_ids[idx]}/>
                                    </List>
                                </Popover>
                                <ListItemText primary={(props.type==='Customer')? `Ad ${idx+1} (${r})`:r}/>
                                {(verifyStates && !verifyStates[idx]) ?
                                    <Tooltip title={<p style={{fontSize:'1.5em'}}>Your meta tag and script tag should be on you real website before you verify</p>} disableFocusListener>

                                    <IconButton
                                    onClick={(e)=>{
                                        if(!loadingIdxLo.includes(idx)){
                                            e.preventDefault();e.stopPropagation();setLoadingIdxLo([...loadingIdxLo,idx]);
                                            hostManager.post(props.token, undefined, `/verify?idx=${idx}&host_id=${props.website_ids[idx]}`).then(res => {
                                                if(res.status_code===200){
                                                    let temp = [...verifyStates];
                                                    temp[idx] = true;
                                                    setVerifyStates([...temp])
                                                }
                                                setLoadingIdxLo(loadingIdxLo.filter(l=>{return l !== idx}))
                                            }).catch(e=>{
                                                console.error(e);
                                                setLoadingIdxLo(loadingIdxLo.filter(l=>{return l !== idx}));
                                                setFailIdxLo([...failIdxLo,idx])
                                            })
                                        }
                                    }}
                                >
                                    {loadingIdxLo.includes(idx) ?
                                        <CircularProgress size={34}/>
                                        :
                                        <Button disabled={loadingIdxLo.includes(idx)}  style={{textDecoration:'underline',color:'blue',textTransform:'none', fontSize:'0.9em'}}>verify</Button>
                                    }
                                </IconButton>
                                    </Tooltip>
                                    :
                                    (props.type === 'Customer')? null : <CheckBoxIcon style={{color:'#00b812'}}/>
                                }
                                <VerifyModal button={(
                                    <IconButton onClick={() => {
                                        window.location.replace(`/website/edit/?i=${idx}&website_id=${props.website_ids[idx]}&verify=true`)
                                    }} aria-label={"Copy site verification"}>
                                        <ShieldIcon style={{color:'rgb(0,89,175)',marginRight:5}}/>
                                        <Typography >View site verification code</Typography>
                                    </IconButton>

                                )} open={failIdxLo.includes(idx)} handleClose={()=>{if(failIdxLo.includes(idx)){setFailIdxLo(failIdxLo.filter(l=>{return l !== idx}))}}}/>
                            </ListItem>
                        )
                    })
                }
            </List>
        </React.Fragment>
    );
}