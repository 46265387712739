import * as React from 'react';
import { Box } from '@mui/system';
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
//
// const StyledModal = styled(ModalUnstyled)`
//   position: fixed;
//   z-index: 1300;
//   right: 0;
//   bottom: 0;
//   top: 0;
//   left: 0;
//   display: flex;
//   align-items: center;
//   background-color: rgba(0, 0, 255);
//   justify-content: center;
// `;

// const Backdrop = styled('div')`
//   z-index: -1;
//   position: fixed;
//   right: 0;
//   bottom: 0;
//   top: 0;
//   left: 0;
//   background-color: rgba(0, 0, 0, 0.5);
//   -webkit-tap-highlight-color: transparent;
// `;

// const style = {
//     width: 400,
//     bgcolor: 'background.paper',
//     p: 2,
//     px: 4,
//     pb: 3,
// };
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '1px solid #000',
    // boxShadow: 24,
    p: 4,
};

export default function VerifyModal(props) {
    return (
        <div>
            <Modal
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                open={props.open}
                onClose={props.handleClose}
                BackdropComponent={Backdrop}
            >
                <Box sx={style}>
                    <h2 id="unstyled-modal-title">Unable to verify site</h2>
                    <p id="unstyled-modal-description">Please copy the site verification code and add it below your site's {"<head>"} tag</p>
                    {props.button}
                </Box>
            </Modal>
        </div>
    );
}