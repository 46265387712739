import React, {Fragment, useEffect, useState} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import {alpha, makeStyles} from "@material-ui/core/styles";
import Title from "../Title";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DevicesIcon from "@material-ui/icons/Devices";
import ListItemText from "@material-ui/core/ListItemText";
import NameDevice from "../AddDeviceSteps/NameDevice";
import {DEFAULT_FPS} from "../../config/CONSTANTS";

const useStyles = makeStyles((theme) => ({
    backButton:{
        marginLeft:5
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        borderColor: 'gray',
        border:'2px solid'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '20ch',
            '&:focus': {
                width: '22ch',
            },
        },
    },
}));

function DeviceCheckListButton(props){
    const [buttonOn,setButtonOn] = useState(props.checked);
    useEffect(()=>{setButtonOn(props.checked)},[props.checked])
    const r= props.r;
    return(
        <ListItem disabled={props.disabled} button onClick={()=>{setButtonOn(!buttonOn);props.onClick(!buttonOn);}}>
            <ListItemIcon>
                <Checkbox checked={buttonOn}/>
            </ListItemIcon>

            <ListItemIcon>
                <DevicesIcon/>
            </ListItemIcon>
            <ListItemText primary={r.name}/>
        </ListItem>
    )
}
function isValidHttpUrl(string) {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}


export default function ChooseDevices(props) {
    const classes = useStyles();
    const [deviceData, setDeviceData] = React.useState({name:props.editDeviceName || "",deviceEndpoint:"", deviceMachineId:"",networkUri:"",cameraOnDevice:true, fps:DEFAULT_FPS, pingServer:false, loEventId:[]});
    const [selectDevice,setSelectDevice] = useState(false);
    const [enterDeviceName,setEnterDeviceName] = useState(false);

    const getDeviceName = () => {
        return deviceData.name
    };

    const setDeviceName = (n) => {
        if(n && n.length>0 && n.length<21 && ((typeof n)==="string")){
            setDeviceData({...deviceData, name:n})
            return [true, ""]
        }
        return [false, "Device name must be 1 to 20 characters"]
    };





    return (
        <React.Fragment>
            <Title>
                Enter a name for your device
            </Title>
            <NameDevice getDeviceName={getDeviceName} onFinish={()=>{return props.handleNext(deviceData.name)}}  first_name={props.first_name} setName={setDeviceName}/>
        </React.Fragment>
    );
}