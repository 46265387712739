import React, {Fragment, useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core/styles";
import ObjectComplete from "../Autocomplete";
import Button from "@material-ui/core/Button";
import Title from "../Title";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
    buttons: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    centerVertically:{
        // minHeight:'50%',
        margin:'auto'
    },
    checkbox: {
        // width: 450,
        // maxWidth:'60vw',
        // '& > * + *': {
        //     marginTop: theme.spacing(3),
        // },
        alignItems:'flex-start',
        marginRight:'auto',
        marginLeft:'12vw',
        marginTop:10
    }
}));


export default function SearchEvents(props) {
    const classes = useStyles();

    const [nextDisabled, setNextDisabled] = useState(!(props.loEventSelected && props.loEventSelected.length));
    const [tooManyEvent, setTooManyEvent] = useState(props.loEventSelected.length > props.max_events_per_device);

    useEffect(()=>{
        const tooMany = (props.loEventSelected.length > props.max_events_per_device);
        setTooManyEvent(tooMany);
        setNextDisabled(!(props.loEventSelected && props.loEventSelected.length && !tooMany))
    },[props.loEventSelected])

    const onLoEventSelected = (lo)=>{
        setNextDisabled(!(lo && lo.length))
        props.onLoEventSelected(lo)
    }

    return (
        <React.Fragment>
            <Title>
                {props.editMode? `Editing keywords` : "Enter keywords"}
            </Title>
            <Grid   container
                    className={classes.centerVertically}
                    direction="column"
                    alignItems="center"
                    justifyContent="center">
                <Grid style={{display:'flex'}} item>
                    <ObjectComplete ready={props.ready} allowMultiple={true} exact={true} defaultValue={props.defaultValue} loEventSelected={props.loEventSelected} onLoEventSelected={onLoEventSelected}/>
                    <Tooltip interactive open={tooManyEvent} placement="top" title={
                        <Fragment>
                            <Typography>You can have at most {props.max_events_per_device} {props.max_events_per_device === 1? "keyword":"keywords"}</Typography>
                        </Fragment>
                    }>
                        <Button
                            disabled={nextDisabled}
                            variant="contained"
                            color="primary"
                            onClick={props.handleNext}
                        >
                            {"Next"}
                        </Button>
                    </Tooltip>
                </Grid>
                {/*<Grid item className={classes.checkbox}>*/}
                {/*    <FormGroup >*/}
                {/*        <FormControlLabel control={*/}
                {/*            <Checkbox defaultChecked={props.defaultCheckedValue} onChange={(e,v)=>{props.showAdInMyCity && props.showAdInMyCity(v)}} inputProps={{*/}
                {/*                'aria-label': 'Check box to only show Ad only in your current city',*/}
                {/*            }} />*/}
                {/*        } label="Show Ad only in my city" />*/}
                {/*    </FormGroup>*/}
                {/*</Grid>*/}
            </Grid>
        </React.Fragment>
    );
}