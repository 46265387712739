import React, {Fragment, useEffect, useRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {TextField, Typography} from "@material-ui/core";
import VisibilityPasswordTextField from "../RegisterLogin/VisibilityPasswordTextField";
// import {attachCaptchaToken} from "../../../../lib/recaptcha";
import ButtonCircularProgress from "../RegisterLogin/ButtonCircularProgress";
// import {postChangePassword} from "../../../../lib/clientUtil/apiManager";
import CheckIcon from "@material-ui/icons/Check";
import {userManager} from "../../util/apiRequest";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor:'red',
        borderWidth:'2px',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),

    },
}));

export default function ChangePasswordModal(props) {
    const classes = useStyles();
    const [understand, setUnderstand] = useState(props.failedDeletion);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [nextDisabled, setNextDisabled] = useState(true);


    const newPassword = useRef();
    const registerPassword = useRef();
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const [target, setTarget] = useState("");
    const [csrf, setCsrf] = useState(props.csrfToken);

    const clearPassword= ()=>{
        if(newPassword && newPassword.current && newPassword.current.value){
            newPassword.current.value='';
            setIsNewPasswordVisible(false);
        }
        if(registerPassword && registerPassword.current && registerPassword.current.value){
            registerPassword.current.value='';
            setIsPasswordVisible(false);
        }
    }

    const clearFields= ()=>{
        try{
            if(newPassword && newPassword.current && newPassword.current.value){
                newPassword.current.value='';
                setIsNewPasswordVisible(false);
            }
            if(registerPassword && registerPassword.current && registerPassword.current.value){
                registerPassword.current.value='';
                setIsPasswordVisible(false);
            }
        }
        catch (e) {

        }
    }



    const submitChangePassword = ()=>{
        setIsLoading(true);

        let userInfo = {
            previousPassword:registerPassword.current.value,
            proposedPassword:newPassword.current.value,
        };
        userManager.post(window.localStorage.getItem('token'),userInfo,'/password').then((r)=>{
            setIsLoading(false);
            if(r){
                if(r.status_code === 200){
                    window.localStorage.setItem('token',r.token)
                    setSuccess(true);
                    setMessage("");
                    setTarget("");
                }
                else{
                    setMessage(r.message)
                    setTarget(r.target);
                }
                clearPassword()

            }
        }).catch(e=>{
            setIsLoading(false);
            setMessage(e.response.data.message)
            setTarget(e.response.data.target);
            clearPassword()
        })

    }

    const captureEmptyInput=(e)=>{
        let password=registerPassword.current.value.length;
        let newPasswordStr=newPassword.current.value.length;

        setNextDisabled(!(password && newPasswordStr));
        if(message && message.length){setMessage('');setTarget('')}
    }

    return (
        <div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props.open}
                onClose={()=>{setSuccess(false);setNextDisabled(true);clearFields();setMessage("");setTarget("");props.handleClose()}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 100,
                }}
            >
                <Fade in={props.open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">
                            Change password
                        </h2>
                        <p id="transition-modal-description">Enter your current credentials and a new password
                        </p>
                        <Grid container>
                                <Fragment>
                                    <form method="POST" onSubmit={(e)=>{e.preventDefault();submitChangePassword();}}>
                                        <input type={'hidden'} name={'_csrf'} value={props.csrfToken} />
                                        <Grid item>
                                            <VisibilityPasswordTextField
                                                onChange={captureEmptyInput}
                                                style={{marginTop: 5, marginBottom: 5}}
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                error={target === "password" && message && message.length}
                                                label={"Current password"}
                                                inputRef={registerPassword}
                                                autoComplete="off"
                                                helperText={(() => {
                                                    if (target === "password" && message && message.length) {
                                                        return message;
                                                    }
                                                    return null;
                                                })()}
                                                FormHelperTextProps={{error: true}}
                                                isVisible={isPasswordVisible}
                                                onVisibilityChange={setIsPasswordVisible}
                                            />
                                            <VisibilityPasswordTextField
                                                onChange={captureEmptyInput}
                                                style={{marginTop: 5, marginBottom: 0}}
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                error={target === "newPassword" && message && message.length}
                                                label={"New password"}
                                                inputRef={newPassword}
                                                autoComplete="off"
                                                helperText={(() => {
                                                    if (target === "newPassword" && message && message.length) {
                                                        return message;
                                                    }
                                                    return null;
                                                })()}
                                                FormHelperTextProps={{error: true}}
                                                isVisible={isNewPasswordVisible}
                                                onVisibilityChange={setIsNewPasswordVisible}
                                            />
                                            {isLoading && <ButtonCircularProgress />}
                                            {(success) &&
                                            <Typography>Your password has been reset
                                                <CheckIcon style={{color:'green', marginBottom:-5,marginLeft:5, fontSize:30}}/>
                                            </Typography>
                                            }
                                        </Grid>


                                    <Grid style={{marginTop:10}} item>
                                        {(!success) &&
                                        <Button disabled={nextDisabled} color={'secondary'} variant={'outlined'} style={{ marginRight:20}}  type={'submit'} >Update password</Button>
                                        }
                                        <Button color={'primary'} variant={'outlined'} onClick={()=> {
                                            setSuccess(false);setNextDisabled(true);clearFields();setMessage("");setTarget("");props.handleClose()
                                        }}>{success? "Ok" : "Cancel"}</Button>
                                    </Grid>
                                    </form>

                                </Fragment>


                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}