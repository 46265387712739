import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import * as CONSTANTS from "../config/CONSTANTS";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import StripeInput from './StripeInput'
import React, {useEffect, useMemo, useState} from "react";
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from "@stripe/react-stripe-js";
import LoadingButton from "./LoadingButton";
import InputBase from '@material-ui/core/InputBase';
import {paymentManager} from "../util/apiRequest";

const op ={style:{base:{lineHeight: "2.0em"}}}

async function postPaymentMethod(url , data = {}) {
    return paymentManager.post(window.localStorage.getItem("token"),data, url);
}

async function postPaymentCheck(url , data = {}) {
    return paymentManager.post(window.localStorage.getItem("token"),data, url);
}

async function postSetupIntent(url , data = {}) {
    return paymentManager.post(window.localStorage.getItem("token"),data, url);
}

const useStyles = makeStyles((theme) => ({
        labelFont: {fontSize:"10px"},
        buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
    }
));

export default function PaymentMethod(props) {
    const classes = useStyles();
    const stripe = useStripe();
    const elements = useElements();
    const nameRef = React.useRef();
    const authorizeRef = React.useRef();
    const [message, setMessage] = useState("");
    const [target, setTarget] = useState("");
    const [nextDisabled,setNextDisabled] = useState(true);
    const [checked,setChecked] = useState(false);
    const [clientSecret,setClientSecret] = useState(props.clientSecret);
    const sku = props.sku;
    const skuAmount = props.skuAmount;

    useEffect(()=>{
        if(elements){
            let myCardElement = elements.getElement(CardNumberElement);
            myCardElement.update(op);

            let myCardElement1 = elements.getElement(CardExpiryElement);
            myCardElement1.update(op);

            let myCardElement2 = elements.getElement(CardCvcElement);
            myCardElement2.update(op);
        }
    },[elements])

    const captureEmptyInput=(e)=> {
        setNextDisabled(!(nameRef.current.value && nameRef.current.value.length>1) || !checked);
        if (message && message.length) {
            setMessage('');
            setTarget('')
        }
    }

    const refreshIntent=()=> {
        setNextDisabled(true)
        postSetupIntent('/setupIntent',{refreshSetupIntent:true}).then((s)=>{
            if(s.status_code === 200){
            }
            else{
                setTarget("cardNumber");
                setMessage("Something went wrong, please refresh the page or contact support");
            }
            setNextDisabled(!(nameRef.current.value && nameRef.current.value.length>1) || !checked);

        }).catch(e=>{
            setTarget("cardNumber");
            setMessage("Something went wrong, please refresh the page or contact support");
            setNextDisabled(!(nameRef.current.value && nameRef.current.value.length>1) || !checked);

        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements || !(nameRef && nameRef.current && nameRef.current.value && nameRef.current.value.length>1)) {
            setTarget("nameOnCard");
            setMessage("Please enter the name on the card")
            return;
        }
        const result = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    name: nameRef.current.value,
                },
            }
        });

        if (result.error) {
            console.error('error in adding card',result)
            const errCode = result.error.code;
            if(errCode.includes('number')){
                setTarget("cardNumber");
                setMessage(result.error.message);
            }
            else if(errCode.includes('expiry')){
                setTarget("expiryDate");
                setMessage(result.error.message)
            }
            else if(errCode.includes('cvc')){
                setTarget("cvc");
                setMessage(result.error.message)
            }
            else if(errCode.includes('unexpected_state')){
                setTarget("cardNumber");
                setMessage(result.error.message+" please try again");
                refreshIntent();
            }
            else{
                setTarget("cardNumber");
                setMessage(result.error.message);
            }
        } else {
            let checkedAuthorize = false;
            if(authorizeRef && authorizeRef.current && authorizeRef.current.value){
                checkedAuthorize = authorizeRef.current.value;
            }
            postPaymentMethod('',{
                sku:sku,
                skuAmount:skuAmount,
                authorizeAppPayment:checkedAuthorize,
                payment_method:result.setupIntent.payment_method,
                client_secret:clientSecret
            }).then(async r=>{
                if(r.status_code===200){
                    if(props.returnUri){
                        window.location.href = props.returnUri
                    }
                    else{
                        window.location.href = '/'
                    }
                }
                else if(r.status_code === 402){
                    setMessage("");
                    const { paymentIntent, error } = await stripe.confirmCardPayment(r.verificationSecret);
                    if(error){
                        setTarget("cardNumber");
                        setMessage("Unable to process that payment method. Please try another method");
                        refreshIntent();
                    }
                    else{
                        postPaymentCheck('',{verificationSubscriptionPaymentId:r.verificationSubscriptionPaymentId}).then(c=>{
                            if(c.status_code === 200){
                                if(props.returnUri){
                                    window.location.href = props.returnUri
                                }
                                else{
                                    window.location.href = '/'
                                }
                            }
                            else{
                                setTarget("cardNumber");
                                setMessage("Unable to process that payment method. Please try another method");
                            }
                        }).catch(e=>{
                            console.error("postPaymentCheck error",e);
                            setTarget("cardNumber");
                            setMessage("Something went wrong, please refresh the page or contact support");
                        })
                    }
                }
                else{
                    refreshIntent();
                    setTarget("cardNumber");
                    setMessage("Unable to process that payment method. Please try another method");
                }
            })
        }
    };

    const onChangeCheck = (e)=>{
        setNextDisabled(!(nameRef.current.value && nameRef.current.value.length>1) || checked);
        setChecked(!checked)
    }

    return (
        <React.Fragment>
            {(props.update) ?
                (props.customer_verify) &&
                <Typography style={{marginBottom: 15, fontSize: '0.8em', fontColor: 'grey'}} component="p" variant="body2"
                            align="left">
                    * You may enter new a card. Only the most recently added card will be used
                </Typography>
                :
            <Typography style={{marginBottom: 15, fontSize: '0.8em', fontColor: 'grey'}} component="p" variant="body2"
                        align="left">
                {((sku === 'Standard')) && "* One month free trial only applies to the Basic plan"}
            </Typography>
            }
            <form onSubmit={handleSubmit} >
                <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        onChange={captureEmptyInput}
                        error={target === "nameOnCard" && message && message.length}
                        FormHelperTextProps={{error: true}}
                        helperText={(() => {
                            if (target === "nameOnCard" && message && message.length) {
                                return message;
                            }
                            return null;
                        })()}
                        variant="outlined" required id="cardName"  inputRef={nameRef} label="Name on card" fullWidth autoComplete="cc-name" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        onChange={captureEmptyInput}
                        error={target === "cardNumber" && message && message.length}
                        FormHelperTextProps={{error: true}}
                        helperText={(() => {
                            if (target === "cardNumber" && message && message.length) {
                                return message;
                            }
                            return null;
                        })()}

                        id="cardNumber"
                        autoComplete="cc-number"
                        label="Credit Card Number"
                        name="ccnumber"
                        variant="outlined"
                        required
                        fullWidth
                        InputProps={{
                            inputComponent: StripeInput,
                            inputProps: {
                                component: CardNumberElement,
                            },
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        onChange={captureEmptyInput}
                        error={target === "expiryDate" && message && message.length}
                        FormHelperTextProps={{error: true}}
                        helperText={(() => {
                            if (target === "expiryDate" && message && message.length) {
                                return message;
                            }
                            return null;
                        })()}
                        label="Expiry Date"
                        autoComplete="cc-exp"
                        name="ccexp"
                        variant="outlined"
                        required
                        fullWidth
                        InputProps={{
                            inputProps: {
                                component: CardExpiryElement
                            },
                            inputComponent: StripeInput
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        onChange={captureEmptyInput}
                        error={target === "cvc" && message && message.length}
                        FormHelperTextProps={{error: target === "cvc" && message && message.length}}
                        helperText={(() => {
                            if (target === "cvc" && message && message.length) {
                                return message;
                            }
                            return "Last three digits on signature strip";
                        })()}
                        label="CVC"
                        name="cvc"
                        variant="outlined"
                        required
                        fullWidth
                        InputProps={{
                            inputProps: {
                                component: CardCvcElement
                            },
                            inputComponent: StripeInput
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox inputRef={authorizeRef} onChange={onChangeCheck} color="secondary" name="authorizeCardPayments" value="yes" />}
                        label={<Typography className={classes.labelFont}>{"I authorise " +CONSTANTS.APP_NAME+ " to take payments from my card in accordance with the Terms of Use."}</Typography>}
                    />
                </Grid>
            </Grid>
            <div className={classes.buttons}>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    onError={()=>{}}
                    onComplete={()=>{}}
                    className={classes.button}
                    disabled={nextDisabled}
                >
                    Next
                </LoadingButton>
            </div>
            </form>
        </React.Fragment>
    );
}