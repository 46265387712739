import React, {Fragment, useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Title from "../Title";
import LinearSlider from "../Slider";

const useStyles = makeStyles((theme) => ({
    buttons: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(2),
    },
    centerVertically:{
        margin:'auto',
    }
}));
const MIN_BUDGET=10;

export default function Budget(props) {
    const classes = useStyles();
    const [budget, setBudget] = React.useState(props.budget || MIN_BUDGET);
    return (
        <React.Fragment>
            <Title>
                Monthly budget for this Ad
            </Title>
            <Grid   container
                    className={classes.centerVertically}
                    direction="column"
                    alignItems="center"
                    justifyContent="center">

                <Grid style={{display:'flex', width:'100%'}} item>
                    <LinearSlider defaultValue={props.budget || MIN_BUDGET} minBudget={MIN_BUDGET} onChange={(e)=>{props.updateBudget(e);setBudget(e)}}/>
                    <Button
                        disabled={typeof budget === 'undefined'}
                        variant="contained"
                        color="primary"
                        style={{marginLeft:10}}
                        onClick={()=>{props.onFinish(budget)}}
                    >
                        {"Finish"}
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}