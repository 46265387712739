import { CircularProgress, Button } from '@material-ui/core';
import React, {useEffect, useState} from "react";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function LoadingButton(props) {
    const { onClick, onComplete, onError } = props;

    if(!(onClick && onComplete && onError)){throw "LoadingButton: Missing props error"}
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);


    const [storeError, setStoreError] = useState();

    const openErrorBar = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        onError(storeError)
    };

    const managedOnClick = async (e) =>{
        console.log('calling managed on click')
        setLoading(true);
        return onClick(e).then(r=>{
            console.log('finished managed on click')

            setLoading(false);
            return onComplete(r)
        }).catch(err=>{
            console.error('LoadingButton error',err)
            openErrorBar();
            setStoreError(err);
            setLoading(false);

        })
    }

    return (
        <div>
            <Button variant={props.variant || "contained"}  color={props.color || "primary"} onClick={managedOnClick} disabled={loading || props.disabled} style={props.style || {}}>
                {loading && <CircularProgress size={14} />}
                {!loading && [...props.children]}
            </Button>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    An unexpected error occurred
                </Alert>
            </Snackbar>
        </div>
    );
}
export default LoadingButton;