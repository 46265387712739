import React from "react";
import PropTypes from "prop-types";
import { Backdrop, withStyles } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  backdrop: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1200,
    position: "fixed",
    touchAction: "none",
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  }
}));

function ModalBackdrop(props) {
  const classes = useStyles()
  const { open } = props;
  return <Backdrop open={open} className={classes.backdrop} />;
}


export default ModalBackdrop;
