import React, { useState, useCallback, useRef, Fragment } from "react";
import {
  TextField,
  Button,
  Checkbox,
  Typography,
  FormControlLabel,
  withStyles, FormLabel,
} from "@material-ui/core";
import FormDialog from "./FormDialog";
import HighlightedInformation from "./HighlightedInformation";
import ButtonCircularProgress from "./ButtonCircularProgress";
import VisibilityPasswordTextField from "./VisibilityPasswordTextField";
import {makeStyles} from "@material-ui/core/styles";
import {CONFIRMATION_CODE_SIZE} from "../../config/CONSTANTS";



const useStyles = makeStyles((theme) => ({
  forgotPassword: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main,
    cursor: "pointer",
    "&:enabled:hover": {
      color: theme.palette.primary.dark,
    },
    "&:enabled:focus": {
      color: theme.palette.primary.dark,
    },
  },
  disabledText: {
    cursor: "auto",
    color: theme.palette.text.disabled,
  },
  formControlLabel: {
    marginLeft:5,
    cursor:'pointer',
    fontSize:'0.9em'
  },
}));

function LoginDialog(props) {
  const {
    setStatus,
    history,
    onClose,
    openChangePasswordDialog,
    openRegisterDialog,
    status,
  } = props;
  const classes = useStyles()

  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const emailOrPNRef = useRef();
  const registerPassword = useRef();
  const [message, setMessage] = useState("");
  const [nextDisabled, setNextDisabled] = useState(true);
  const [target, setTarget] = useState("");

  const captureEmptyInput=(e)=>{
    let email_or_pn=emailOrPNRef.current.value.length;
    let password=registerPassword.current.value.length;
    setNextDisabled(!(email_or_pn && password));
    if(message && message.length){setMessage('');setTarget('')}

  }
  //
  // const submitUserLogIn = ()=>{
  //   setIsLoading(true);
  //   console.log('old csrf',csrf)
  //   const userInfo = {
  //     email_or_pn:emailOrPNRef.current.value,
  //     password:registerPassword.current.value,
  //     _csrf:csrf, _uuid:uuid,
  //   }
  //
  //   postUserInfo(userInfo).then((r)=>{
  //     setIsLoading(false);
  //     if(r){
  //       console.log('new csrf',r.csrfToken)
  //
  //       setBeforeCode(!r.codeSent)
  //       setMessage(r.message)
  //       setTarget(r.target);
  //       setCsrf(r.csrfToken);
  //       if(r.codeSent){
  //         setNextDisabled(true)
  //       }
  //       if(r.codeSent && registerPassword && registerPassword.current && registerPassword.current.value){
  //         registerPassword.current.value='';
  //       }
  //     }
  //   })
  // }

  return (
    <Fragment>
      <FormDialog
          disableBackdropClick
        open
        onClose={onClose}
        loading={isLoading}
        onFormSubmit={(e) => {
          e.preventDefault();
          login();
        }}
        hideBackdrop
        headline="Notifycam"
        content={
          <Fragment>
            <TextField
                onChange={captureEmptyInput}
                inputRef={emailOrPNRef}
                variant="outlined"
                margin="normal"
                fullWidth
                error={target === "email_or_pn" && message && message.length}
                label="Email address or phone number"
                autoComplete="off"
                type="text"
                FormHelperTextProps={{error: true}}
                helperText={(() => {
                  if (target === "email_or_pn" && message && message.length) {
                    return message;
                  }
                  return null;
                })()}
            />
            <VisibilityPasswordTextField
                onChange={captureEmptyInput}
                // style={{marginTop: 5, marginBottom: 0}}
                variant="outlined"
                margin="normal"
                fullWidth
                error={target === "password" && message && message.length}
                label="Password"
                inputRef={registerPassword}
                autoComplete="off"
                helperText={(() => {
                  if (target === "password" && message && message.length) {
                    return message;
                  }
                  return null;
                })()}
                FormHelperTextProps={{error: true}}
                isVisible={isPasswordVisible}
                onVisibilityChange={setIsPasswordVisible}
            />
            <FormLabel
                onClick={openChangePasswordDialog}
              className={classes.formControlLabel}
            >Forgot password?
            </FormLabel>

          </Fragment>
        }
        actions={
          <Fragment>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoading}
              size="large"
            >
              Log in
              {isLoading && <ButtonCircularProgress />}
            </Button>
            <Typography
              align="center"
              className={classNames(
                classes.forgotPassword,
                isLoading ? classes.disabledText : null
              )}
              color="primary"
              onClick={openRegisterDialog}
              tabIndex={0}
              role="button"
              // onClick={()=>{}}
            >
              Don't have an account? Sign up
            </Typography>
          </Fragment>
        }
      />
    </Fragment>
  );
}

export default LoginDialog;
