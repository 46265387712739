import React from 'react';
import Typography from '@material-ui/core/Typography';
import {APP_NAME} from "../config/CONSTANTS";
export default function Copyright() {
  return (
    <Typography style={{fontSize:'0.8em'}} variant="body2" color="textSecondary" align="center">
      {'© '}
      {new Date().getFullYear()}
      {', '}
      {APP_NAME}
      {' Inc. or its affiliates'}

    </Typography>
  );
}
