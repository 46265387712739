import React from "react";
import { Typography, withStyles } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: theme.palette.warning.light,
    border: `${theme.border.borderWidth}px solid ${theme.palette.warning.main}`,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius
  }
}));



function HighlighedInformation(props) {
  const classes = useStyles()
  const { className, children } = props;
  return (
    <div className={classNames(classes.main, className ? className : null)}>
      <Typography variant="body2">{children}</Typography>
    </div>
  );
}

export default HighlighedInformation;
