import React, {Fragment} from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import WebIcon from '@mui/icons-material/Web';
import ImageIcon from '@mui/icons-material/Image';
import {Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import * as CONSTANTS from "../config/CONSTANTS";
import Toolbar from "@material-ui/core/Toolbar";

const useStyles = makeStyles({
    depositContext: {
        flex: 1,
    },
    listRoot: {
        width: '100%',
        height:'80vh',
        textAlign:'center',
        margin:'auto'
    },
    title: {
        flexGrow: 1,
        color:'black',
        fontWeight:'600',
        marginLeft:0,
        marginRight:'auto',
        textAlign:'left',

    },
    imgIcon: {
        marginTop:0,
        marginBottom:0,
        paddingTop:0,
        paddingBottom:0,
        cursor:'default',
        pointerEvents: 'none'
    },
});


export default function FindAccountTypeIntentModal(props) {
    const classes = useStyles();

    return (
        <Fragment>
            <List className={classes.listRoot} aria-label="main social signup">
                <ListItem style={{width:'100%',textAlign:'center',marginTop:'5vh',marginBottom:0}} alignItems="center" >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        className={classes.imgIcon}
                    >
                        <img className={classes.imgIcon} src="/favicon.svg" width={75} height={75} alt="Notifycam logo"/>
                    </IconButton>
                    {/*</Hidden>*/}
                    <Typography component="h1" variant="h3" noWrap className={classes.title}>
                            {CONSTANTS.APP_NAME}
                    </Typography>
                </ListItem>
                    <ListItem button  component="a" onClick={(e)=>{
                        document.cookie = "redirect=account;domain=.notifycam.com";
                        window.location.href = 'https://auth.notifycam.com';
                    }} style={{width:'100%',textAlign:'left',marginTop:'12vh'}} alignItems="center" >
                        <ListItemIcon>
                            <WebIcon />
                        </ListItemIcon>
                        <ListItemText  primary={
                            <div style={{fontSize:'1.4em'}}>
                            Earn money from ads on your website
                            </div>
                        }/>
                    </ListItem>
                <ListItem button  component="a"
                        onClick={(e)=>{
                            document.cookie = "redirect=card;domain=.notifycam.com";//
                            window.location.href = 'https://auth.notifycam.com';
                            }}
                          style={{width:'100%',textAlign:'left',marginTop:'5%'}} alignItems="center">
                    <ListItemIcon>
                        <ImageIcon />
                    </ListItemIcon>
                    <ListItemText primary={
                        <div style={{fontSize:'1.4em'}}>
                            Advertise your product or service
                        </div>
                    }/>
                </ListItem>
                <ListItem
                    style={{width:'100%',textAlign:'center',marginTop:'2vh'}} alignItems="center">
                    <ListItemText primary={
                        <div style={{fontSize:'1.0em', float:'left',marginLeft:0,textAlign:'left', fontWeight:'500'}}>
                            Or
                        </div>
                    }/>
                </ListItem>
                <ListItem button  component="a"
                          onClick={(e)=>{
                              window.location.href = 'https://auth.notifycam.com';
                          }}
                          style={{width:'100%',textAlign:'center',marginTop:'2vh'}} alignItems="center">

                    <ListItemText primary={
                        <div style={{fontSize:'1.2em', color:'blue', float:'left',marginLeft:0,textAlign:'left'}}>
                            Sign in to existing account
                        </div>
                    }/>
                </ListItem>
            </List>
        </Fragment>
    );
}

