import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import {makeStyles} from "@material-ui/core/styles";
import CategoryIcon from '@material-ui/icons/Category';
import Typography from "@material-ui/core/Typography";
// import DevicesIcon from "@material-ui/icons/Devices";
import AddIcon from "@material-ui/icons/Add";
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import HomeIcon from '@material-ui/icons/Home';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import MessageIcon from '@material-ui/icons/Message';
// import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
// import {CONTENT_STEP_1_IMG, CONTENT_STEP_2_IMG, CONTENT_STEP_3_IMG} from "../config/CONSTANTS";
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import WebIcon from '@mui/icons-material/Web';
const linkStyle = {
    color: "inherit",
    textDecoration:'none'
}

const sideItems = [
    {
        icon:<HomeIcon/>,//< DashboardIcon />,
        name:"Dashboard",
        linkNav:'/'
    },
    {
        icon: <AddIcon/>,
        name:"Create advertisement",
        linkNav:'/create'
    },
    {
        icon:<PersonIcon />,
        name:"User",
        linkNav:'/user'
    },
]

const sideItemsWebsite = [
    {
        icon:<HomeIcon/>,//< DashboardIcon />,
        name:"Dashboard",
        linkNav:'/'
    },
    {
        icon: <AddIcon/>,
        name:"Add a website",
        linkNav:'/website/create'
    },
    {
        icon:<PersonIcon />,
        name:"User",
        linkNav:'/user'
    },
]

// export const mainListItems = (

export const mainListItems = (
    <div>
        {
            sideItems.map((item,idx)=>{
                return(
                    <a key={idx} style={linkStyle} href={item.linkNav}>
                        <ListItem button>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItem>
                    </a>
                )
            })
        }
    </div>
);

export const mainListItemsWebsite = (
    <div>
        {
            sideItemsWebsite.map((item,idx)=>{
                return(
                    <a key={idx} style={linkStyle} href={item.linkNav}>
                        <ListItem button>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItem>
                    </a>
                )
            })
        }
    </div>
);



const indexSideItems = [
    {
        icon:<SearchIcon/>,
        name:"Search events",
        linkNav:'/#'
    },
    {
        icon:<CategoryIcon/>,//< DashboardIcon />,
        name:"Tutorials",
        linkNav:'/#steps'
    },
    {
        icon:<PersonIcon />,
        name:"Sign up",
        linkNav:'/#signup'
    },
    {
        icon:<AttachMoneyOutlinedIcon />,
        name:"Plans & pricing",
        linkNav:'/#pricing'
    },
    {
        icon: <MessageIcon/>,
        name:"Learn more",
        linkNav:'/getting-started/learn-more'
    },

]

// export const mainListItems = (

export const indexListItems =(f)=> (
    <div>
        {
            indexSideItems.map((item,idx)=>{
                return(
                    <a key={idx} style={linkStyle} href={item.name==='Sign up'? undefined: item.linkNav}>
                        <ListItem onClick={()=>{
                            if(item.name==='Sign up'){
                                f()
                            }
                        }} button>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItem>
                    </a>
                )
            })
        }


    </div>
);

const secondaSideItems = [

    {
        name:'Create an Ad',
        icon:<PageviewOutlinedIcon/>,
        linkNav:'https://notifycam.com/#create_ad'
    },
    {
        name:'Connect your website',
        icon:<WebIcon/>,
        linkNav:'https://notifycam.com/#connect_website'
    },
    {
        name:'Measure results',
        icon:<ShowChartIcon/>,
        linkNav:'https://notifycam.com/#measure_value'
    }
];

export const threeSteps = [
    {
        cardHead:'Create an ad in less than 5 min',
        cardBody:"With Notifycam, you're completely in control of your budget. You can select keywords to maintain brand safety and your preferred call-to-action.",
        cardImage:'/licensed/add_ad.png',
        alt:'Create an ad in less than 5 min. Watch the tutorial',
        cardNav:'/tutorial/create-ad'
    },
    {
        cardHead:'Start earning money through ads',
        cardBody:"All you need to do is copy and paste some HTML and we'll take care if the rest. We'll guide you through the process in only three quick steps",
        cardImage:'/licensed/add_website.png',
        alt:'Start earning money through ads',
        cardNav:'/tutorial/connect-website'
    },
    {
        cardHead:'Measure your campaigns',
        cardBody:"Clicks and impressions are instantly measured for you. We also provide some common statistics",
        cardImage:'/licensed/chart_demo.png',
        alt:'Measure your campaigns, clicks, impressions and more',
        cardNav:'/tutorial/measure-campaign'
    },

];

const useStyles = makeStyles((theme) => ({
    secondaryText:{
        fontSize:'12px',
    }
}));

export const SecondaryListItems = (props) =>{
    const classes = useStyles();
    return (
        <div>
            <ListSubheader inset>Tutorials</ListSubheader>

            {
                secondaSideItems.map((item,idx)=>{
                    return(
                        <a key={idx} style={linkStyle} href={item.linkNav} target="_blank" rel="noopener noreferrer">
                            <ListItem button>
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                    <React.Fragment>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            className={classes.secondaryText}
                                            color="textSecondary"
                                        >
                                            {item.name}
                                        </Typography>
                                    </React.Fragment>
                                } />
                            </ListItem>
                        </a>
                    )
                })
            }


        </div>
    );
}

