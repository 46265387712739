import React, {Fragment, useEffect, useState} from 'react';
import clsx from 'clsx';
import { makeStyles,alpha } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems, SecondaryListItems } from '../components/listItems';
import SimplePopover from '../components/PopOver';
import Title from "../components/Title";
import * as CONSTANTS from "../config/CONSTANTS";
import SearchEvents from "../components/AddEventSteps/SearchEvents";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import AddNotificationEndpoints from "../components/AddEventSteps/AddNotificationEndpoints";
import Copyright from "../components/Copyright";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import UploadAd from "../components/AddEventSteps/UploadAd";
import Budget from "../components/AddEventSteps/Budget";
import {useNavigate} from "react-router-dom";
import {hostManager, userManager} from "../util/apiRequest";
import WebsiteLocation from "../components/AddEventSteps/WebsiteLocation";
import CopyCode from "../components/AddEventSteps/CopyCode";
import {ID_SIZE} from "../config/CONSTANTS";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor:theme.palette.background.appGray

    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: "2%",
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        visibility:"visible",

    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
        [theme.breakpoints.down('sm')]: {
            width: 0,
            // visibility:"hidden",
            display:'none',
        },
        [theme.breakpoints.up('md')]: {
            width: theme.spacing(9),
            visibility:"visible",
        },
        [theme.breakpoints.up('lg')]: {
            width: theme.spacing(9),
            visibility:"visible",
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        // overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        minHeight: 500,
        height: "100%",

    },
    stepper:{
        marginLeft:0,
        paddingLeft:0
    },
    completed:{
        color:'#19857b !important'
    },
}));

const steps = ["Where's your website?", 'Confirm domain', 'Copy code'];

export default function AddWebsite(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [eventData, setEventData] = React.useState({image:"",budget:0,action:"",source:"",loEventSelected:[]});
    let navigate = useNavigate();
    const [displayAppLogo, setDisplayAppLogo] = React.useState(true);
    const [readyToRenderAutocomplete, setReadyToRenderAutocomplete] = React.useState(!props.wait);
    const [editDeviceName, setEditDeviceName] = React.useState();
    const [error, setError] = React.useState();
    const [loading, setLoading] = React.useState();
    const [done, setDone] = React.useState();
    const [disableNext, setDisableNext] = React.useState(false);
    const [hostId, setHostId] = React.useState();
    const [hostUri, setHostUri] = React.useState();

    const [token, setToken] = React.useState(window.localStorage.getItem("token"));
    const [userType, setUserType] = React.useState();

    useEffect(()=>{
        userManager.get(token,undefined,'/type').then(r=>{
            if(r.status_code === 200){
                setUserType(r.type)
            }
        }).catch(e=>{
            console.error("error",e);
        })
    },[])


    useEffect(()=>{
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        let step;
        if(typeof params.i === 'string' && typeof params.website_id === 'string'){
            const low = params.website_id.toLowerCase();
            if(!(low.includes('/') || low.includes('<') || low.includes('%') || low.includes('>') || low.includes('"') || low.length !== ID_SIZE)){
                setDisableNext(true);
                setHostId(params.website_id);
                step=2;
            }
        }
        if(typeof params.verify === 'string'){
            if(params.verify==='true'){
                step=1;
            }
        }
        if(typeof step !== 'undefined'){
            setActiveStep(step);
        }
    },[])

    const _postEvent = async (host_uri) => {
        setLoading(true);
        return hostManager.post(window.localStorage.getItem("token"),{host_uri})
            .then(r=>{
                setLoading(false);
                if(r.status_code === 201){
                    setHostId(r.host_id);
                    setHostUri(r.host_uri);
                    handleNext()
                } else {
                    setError(e.response.data.message?e.response.data.message:"Please confirm your website's domain.")
                }

            }).catch(e=>{setError(e.response.data.message?e.response.data.message:"Please confirm your website's domain.")})
    };

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <WebsiteLocation error={error} handleBack={handleBack} handleNext={handleNext} onChange={(u)=>{return _postEvent(u)}} activeStep={activeStep}/>;
            case 1:
                return <CopyCode meta error={error} disableNext={disableNext} handleBack={handleBack} handleNext={handleNext} code={hostId} activeStep={activeStep}/>;
            case 2:
                return <CopyCode error={error} disableNext={disableNext} handleBack={handleBack} handleNext={()=>{setDone(true)}} code={hostId} activeStep={activeStep}/>;
            default:
                throw new Error('Unknown step');
        }
    }

    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    {displayAppLogo &&
                    <React.Fragment>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            href='/'
                            className={classes.imgIcon}
                        >
                            <img className={classes.imgIcon} src="/favicon.svg" width={40} height={40}
                                 alt="Notifycam logo"/>
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            <a href='/' style={{textDecoration: 'none', color: 'inherit'}}>
                                {CONSTANTS.APP_NAME}
                            </a>
                        </Typography>
                    </React.Fragment>
                    }
                    <SimplePopover/>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <Typography>
                        Welcome {props.first_name}
                    </Typography>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>{mainListItems}</List>
                <Divider />
                <List><SecondaryListItems/></List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3} justify="center" alignItems="center">
                        {/* Chart */}
                        <Grid item xs={12} md={8} lg={9}>
                            <Paper className={fixedHeightPaper}>
                                <Title>
                                    {editDeviceName? `Editing ${editDeviceName}`:props.hide?"Your website" :"Add website"}
                                </Title>
                                {done?
                                    <Button style={{marginTop:'20%'}} onClick={()=>{navigate('/')}}> <ArrowBackIcon/>View on Dashboard</Button>
                                    :
                                    <Fragment>
                                        <Stepper activeStep={activeStep} className={classes.stepper}>
                                            {steps.map((label) => (
                                                <Step key={label}>
                                                    {(!props.hide) &&
                                                    <StepLabel StepIconProps={{classes: {completed: classes.completed}}}>{label}</StepLabel>
                                                    }
                                                </Step>
                                            ))}
                                        </Stepper>
                                        {getStepContent(activeStep)}
                                    </Fragment>
                                }
                                {!(props.hide) &&
                                <div className={classes.buttons} style={{marginTop:'auto'}}>
                                    {((activeStep !== 0) && (!done)) && (
                                        <Button onClick={handleBack} className={classes.button}>
                                            <ArrowBackIcon/>
                                        </Button>
                                    )}
                                </div>
                                }

                            </Paper>

                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Copyright />
                    </Box>
                </Container>
            </main>
        </div>
    );
}

// AddEvent.getInitialProps = async (rec) => {
//     return {wait:rec.query.wait, q:rec.query.q, csrfToken: rec.query.csrfToken,
//         max_notifications_per_month:rec.query.max_notifications_per_month,
//         max_events_per_device:rec.query.max_events_per_device,
//         first_name:rec.query.first_name, email:rec.query.email, device_id: rec.query.device_id}
// }
